import React from "react";
import { faBars, faPhone, faXmark } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDImage,
  TXDText,
} from "@savedaily/txd-components";
import rollOverLogo from "../assets/images/header-logo.png";

import { Link } from "react-router-dom";
import { resetRegistration } from "@/redux/slices/registrationSlice";
import { AppDispatch } from "@/redux/store";
import { useDispatch } from "react-redux";

const SignupNavbar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const resetRegistrationRedux = () => {
    dispatch(resetRegistration());
  };
  return (
    <Disclosure as="nav" className="bg-white shadow-sm sticky">
      {({ open }) => (
        <>
          <TXDFlex className="py-4">
            <TXDFlex className="w-full gap-16 xl:gap-14 pt-3 px-4 justify-between xl:justify-evenly">
              <TXDFlex className="justify-center">
                <Link className="" to="/">
                  <TXDImage
                    src={rollOverLogo}
                    alt="save-daily-logo"
                    className="h-8 sm:h-12 md:h-7 lg:h-7 xl:h-10 2xl:h-10"
                  />
                </Link>
              </TXDFlex>
              <TXDFlex className="hidden xl:flex xl:gap-4 2xl:gap-10">
                <Link
                  to="#"
                  className="flex flex-col justify-center items-center text-gray-900 navLink"
                >
                  <TXDText
                    as="span"
                    className="text-[#506690] text-[15px] xl:text-[17px] navText"
                  >
                    Safe Harbor
                  </TXDText>
                  <TXDText
                    as="span"
                    className="text-[#506690]   text-[15px] xl:text-[17px] navText"
                  >
                    Rollovers
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="flex flex-col justify-center items-center text-gray-900 navLink"
                >
                  <TXDText
                    as="span"
                    className="text-[#506690] text-[15px] xl:text-[17px] navText"
                  >
                    Professionals
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="flex flex-col justify-center items-center text-gray-900 navLink"
                >
                  <TXDText
                    as="span"
                    className="text-[#506690] text-[15px] xl:text-[17px] navText"
                  >
                    Uncashed
                  </TXDText>
                  <TXDText
                    as="span"
                    className="text-[#506690] text-center font-normal text-[15px] xl:text-[17px] navText"
                  >
                    Checks
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="flex flex-col justify-center items-center text-gray-900 navLink"
                >
                  <TXDText
                    as="span"
                    className="text-[#506690] text-[15px] xl:text-[17px] navText"
                  >
                    Benefits Election
                  </TXDText>
                  <TXDText
                    as="span"
                    className="text-[#506690] text-center font-normal text-[15px] xl:text-[17px] navText"
                  >
                    Notifications
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="flex flex-col justify-center items-center text-gray-900 navLink"
                >
                  <TXDText
                    as="span"
                    className="text-[#506690] text-[15px] xl:text-[17px] navText"
                  >
                    Individuals
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="flex flex-col justify-center items-center text-gray-900 navLink"
                >
                  <TXDText
                    as="span"
                    className="text-[#506690] text-[15px] xl:text-[17px] navText"
                  >
                    About us
                  </TXDText>
                </Link>
              </TXDFlex>
              <TXDFlex className="hidden xl:flex xl:flex-row xl:gap-3 2xl:gap-5">
                <TXDBox className="flex gap-2">
                  <FontAwesomeIcon
                    className="mt-3 font-semibold text-[15px] xl:text-[17px] bg-transparent"
                    icon={faPhone}
                  />
                  <a
                    className="py-2 text-[#00AFD8] font-normal whitespace-nowrap text-[15px] xl:text-[17px] bg-transparent"
                    href="tel:+1 877 728 3359"
                  >
                    (877) 728-3359
                  </a>
                </TXDBox>

                <Link to="/start-rollover">
                  <TXDButton
                    className="bg-[#52A546] text-white text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-white hover:text-[#52A546] hover:ring-[#52A546] hover:shadow-lg"
                    radius="full"
                    size="sm"
                    variant="secondary"
                    border={false}
                    onClick={resetRegistrationRedux}
                  >
                    Registration
                  </TXDButton>
                </Link>
              </TXDFlex>
              <TXDFlex className="-mr-2 flex items-center xl:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#52A546]">
                  {open ? (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="block h-6 w-6 "
                      aria-hidden="true"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      className="block h-6 w-6 "
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </TXDFlex>
            </TXDFlex>
          </TXDFlex>
          <Disclosure.Panel className="  xl:hidden">
            <TXDBox className="space-y-1 pb-3 pt-2 flex flex-col">
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-[#52A546] bg-[#d7ead9] py-2 pl-3 pr-4 text-base font-medium text-[#47ab37]"
              >
                Safe Harbor Rollovers
              </Disclosure.Button>

              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Professionals
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Uncashed Checks
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Benefits Election Notifications
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Individuals
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                About us
              </Disclosure.Button>
            </TXDBox>
            <TXDBox className="border-t border-gray-200 pb-3 pt-3">
              <TXDBox className="flex justify-between px-4">
                <TXDBox className="flex gap-2">
                  <FontAwesomeIcon
                    className="mt-3 font-semibold text-[15px] xl:text-[17px] bg-transparent"
                    icon={faPhone}
                  />
                  <a
                    className="py-2 text-[#00AFD8] font-normal whitespace-nowrap text-[15px] xl:text-[17px] bg-transparent"
                    href="tel:+1 877 728 3359"
                  >
                    (877) 728-3359
                  </a>
                </TXDBox>
                <Link to="/start-rollover">
                  <TXDButton
                    className=" bg-[#52A546]   text-white text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 
            hover:bg-white hover:text-[#52A546] hover:ring-[#52A546] hover:shadow-lg"
                    radius="full"
                    size="sm"
                    variant="secondary"
                    border={false}
                  >
                    Registration
                  </TXDButton>
                </Link>
              </TXDBox>
            </TXDBox>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default SignupNavbar;
