import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { TXDBox, TXDText, TXDFlex, TXDButton } from "@savedaily/txd-components";
import MainInformationStepper from "./MainInformationStepper";
import ReviewStepper from "./ReviewStepper";
import {
  IAddParticipantModalProps,
  IStep,
  IMainInformationStepperProps,
} from "@/interfaces";
import { resetForm } from "@/redux/slices/participantFormSlice";
import { AppDispatch, RootState } from "@/redux/store";
import { useDispatch, useSelector, useStore } from "react-redux";
import { INTERNAL_PARTICIPANT_ADD_REQ } from "@/services/api/userService";
import toast from "react-hot-toast";
import SaveSpinner from "../skeletons/SaveSpinner";
import { validateRequiredFields } from "@/utils/componentUtils";

const MainInformationStepperWrapper: React.FC<IMainInformationStepperProps> = ({
  closeModal,
  modalOpen,
  sponsorId,
  onParticipantAdded,
}) => {
  return (
    <MainInformationStepper
      onParticipantAdded={onParticipantAdded}
      closeModal={closeModal}
      modalOpen={modalOpen}
      sponsorId={sponsorId}
    />
  );
};

const AddNewParticipantModal: React.FC<IAddParticipantModalProps> = ({
  modalOpen,
  closeModal,
  sponsorId,
  onParticipantAdded,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const portfolioPermId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const AccessCode = useSelector((state: RootState) => state.auth.AccessCode);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedSponsorId, setSelectedSponsorId] = useState<any>("");
  const store = useStore();
  const [buttonStates, setButtonStates] = useState({
    cancel: false,
    next: false,
    previous: true,
    save: true,
  });

  // Example usage:
  const requiredFieldsObject = {
    sponsor: "",
    firstName: "",
    lastName: "",
    taxId: "",
    dob: "",
    streetAddress: "",
    stateProvinces: "",
    city: "",
    zipCode: "",
    country: "",
  };

  const handleSubmit = async (e: any) => {
    const reduxData = store.getState() as RootState;
    // console.log("This is form data => ", reduxData.participantForm);

    setLoading(true);
    e.preventDefault();
    const formattedPhone = reduxData.participantForm.phoneNo.replace(/\D/g, "");

    const apiData = {
      AgentPermId: portfolioPermId,
      SponsorPermId: Number(reduxData.participantForm.sponsor),
      FirstName: reduxData.participantForm.firstName,
      MiddleName: reduxData.participantForm.middleName,
      LastName: reduxData.participantForm.lastName,
      TaxId: reduxData.participantForm.taxId,
      DOB: reduxData.participantForm.dob,
      Phone: formattedPhone,
      EmailAddr: reduxData.participantForm.email,
      Addr1: reduxData.participantForm.streetAddress,
      Addr2: reduxData.participantForm.streetAddress2,
      StateProv: reduxData.participantForm.stateProvinces,
      City: reduxData.participantForm.city,
      PhoneType: formattedPhone.length,
      PostalCode: reduxData.participantForm.zipCode,
      RMD: reduxData.participantForm.rmd,

      RothIRA: reduxData.participantForm.RothIRA,
      TraditionalIRA: reduxData.participantForm.TraditionalIRA,
      RothIRAAmount: reduxData.participantForm.RothIRA
        ? reduxData.participantForm.RothIRAAmount
        : "",
      TraditionalIRAAmount: reduxData.participantForm.TraditionalIRA
        ? reduxData.participantForm.TraditionalRothIRAAmount
        : "",

      EmpCountry: reduxData.participantForm.EmployeeCounrty,
      EmpName: reduxData.participantForm.EmployeeName,
      Country: reduxData.participantForm.Country,
      OccupationPermId: reduxData.participantForm.occupation,
    };
    console.log("This is data => ", apiData);

    if (reduxData.participantForm.hasError) {
      toast.error(
        "Error found in inputs. Please correct them before submitting."
      );
      setLoading(false);
      return;
    } else {
      if (
        reduxData.participantForm.RothIRA ||
        reduxData.participantForm.TraditionalIRA
      ) {
        try {
          const response = await INTERNAL_PARTICIPANT_ADD_REQ(
            AccessCode,
            apiData
          );
          if (response) {
            toast.success("Participant added successfully");
            if (onParticipantAdded) {
              onParticipantAdded();
            }
            closeModal();
            // location.reload();
          } else {
            throw new Error("Invalid response from server");
          }
        } catch (error) {
          toast.error(String(error));
        } finally {
          setLoading(false);
        }
      } else {
        toast.error("Please select at least one amount method");
      }
    }
  };

  const goToStep = (stepIndex: number) => {
    setCurrentStep(stepIndex);
  };

  const steps: IStep[] = [
    {
      id: "Step 1",
      name: "Main Information",
      component: (props) => (
        <MainInformationStepperWrapper
          {...props}
          sponsorId={sponsorId}
          onParticipantAdded={onParticipantAdded}
        />
      ),
    },
    { id: "Step 2", name: "Review", component: ReviewStepper },
  ];

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleCloseModal = () => {
    setSelectedSponsorId(null);
    dispatch(resetForm());
    closeModal();
  };

  const handleNextStep = () => {
    const reduxData = store.getState() as RootState;
    const validateData = validateRequiredFields(
      requiredFieldsObject,
      reduxData.participantForm
    );

    if (validateData.success) {
      if (reduxData.participantForm.hasError) {
        toast.error("Error founds in input fields");
      } else {
        goToStep(1);
        setButtonStates({
          cancel: true,
          next: true,
          previous: false,
          save: false,
        });
      }
    } else {
      toast.error(validateData.message);
    }
  };

  return (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center "
        onClose={() => {
          //Do nothing
        }}
        data-testid="addNewParticipantModal"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
            <TXDBox className="inline-block w-full max-w-5xl mx-12 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <TXDFlex
                justifyContent="center"
                className="participant-table "
                alignItems="center"
                style={{ maxHeight: "100vh", overflowY: "auto" }}
              >
                <TXDBox className="modalOverlay w-full overflow-y-scroll">
                  <TXDFlex
                    className="p-4 flex justify-between border-b"
                    alignItems="center"
                  >
                    <TXDText
                      align="left"
                      as="h1"
                      className="uppercase  text-gray-500 sm:text-xl text-md whitespace-nowrap  "
                      size="xl"
                      weight="normal"
                    >
                      ADD NEW PARTICIPANT
                    </TXDText>
                    <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                        onClick={() => {
                          dispatch(resetForm());
                          closeModal();
                        }}
                      />
                    </TXDBox>
                  </TXDFlex>

                  <TXDFlex
                    variant="column"
                    className=" md:flex-row md:text-center justify-between bg-gray-100 py-4 px-4"
                  >
                    <TXDFlex
                      alignItems="center"
                      justifyContent="center"
                      className="  sm:justify-start gap-4 mb-2 md:mb-0"
                    >
                      <TXDText
                        align="left"
                        as="h1"
                        className="uppercase text-gray-500"
                        size="sm"
                        weight="normal"
                      >
                        {/* For Company */}
                      </TXDText>
                      <TXDText
                        as="h1"
                        className=" pl-4 text-gray-400"
                        size="sm"
                        weight="normal"
                      >
                        {/* Globex, Inc */}
                      </TXDText>
                    </TXDFlex>
                  </TXDFlex>
                  <nav aria-label="Progress" className="relative mt-4 px-4 ">
                    <ol
                      role="list"
                      className="relative space-y-4 sm:flex md:space-x-4 md:space-y-0 flex sm:flex-row flex-row justify-between"
                    >
                      <TXDBox
                        className="absolute bottom-4 left-0 w-full h-[0.2rem] "
                        style={{ transition: "background-color 0.3s ease" }}
                      >
                        {steps.map((step, index) => (
                          <TXDBox
                            key={step.id}
                            className="h-full"
                            style={{
                              width: `${100 / steps.length}%`,
                              backgroundColor:
                                index === currentStep ? "#6ea7eb" : "#D3D3D3",
                              transition: "left 0.3s ease",
                              borderRadius: index === currentStep ? "1px" : "0",
                              display: "inline-block",
                            }}
                          />
                        ))}
                      </TXDBox>

                      {steps.map((step, index) => (
                        <li
                          key={step.id}
                          className={`sm:flex-1   ${
                            index === currentStep
                              ? "text-[#4EC6E5]"
                              : "text-gray-500"
                          }`}
                          // onClick={() =>
                          //   index === 0 ? goToStep(index) : handleNextStep
                          // }
                        >
                          <TXDFlex variant="column">
                            <TXDText
                              as="span"
                              size="xs"
                              weight="bold"
                              className={`sm:ml-8 ml-0 uppercase ${
                                index === currentStep
                                  ? "text-[#4EC6E5]"
                                  : "text-gray-500"
                              }`}
                            >
                              {step.id}
                            </TXDText>
                            <TXDText
                              as="span"
                              size="xl"
                              weight="normal"
                              className={`sm:ml-8 ml-0 pb-4 ${
                                index === currentStep
                                  ? "text-[#4EC6E5]"
                                  : "text-gray-500"
                              } sm:text-xl text-sm`}
                            >
                              {step.name}
                            </TXDText>
                          </TXDFlex>
                        </li>
                      ))}
                    </ol>
                  </nav>

                  <TXDBox
                    style={{ maxHeight: "70vh", overflowY: "auto" }}
                    className="participant-table pb-6"
                  >
                    {steps.map((step, index) => (
                      <Fragment key={step.id}>
                        {index === currentStep &&
                          React.createElement(
                            step.component,
                            index === 0 ? { closeModal } : {}
                          )}
                      </Fragment>
                    ))}
                  </TXDBox>
                </TXDBox>
              </TXDFlex>
              <TXDBox>
                <TXDFlex
                  justifyContent="end"
                  className="gap-4 px-4 py-4 bg-white fixed bottom-0 left-0 right-0 flex-col-reverse sm:flex-row z-30"
                >
                  <TXDBox hidden={buttonStates.cancel}>
                    <TXDButton
                      className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 sm:w-[115px]"
                      radius="full"
                      variant="secondary"
                      type="button"
                      border={false}
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </TXDButton>
                  </TXDBox>
                  <TXDBox hidden={buttonStates.next}>
                    <TXDButton
                      className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                      radius="full"
                      variant="primary"
                      type="button"
                      onClick={handleNextStep}
                    >
                      NEXT
                    </TXDButton>
                  </TXDBox>

                  <TXDBox hidden={buttonStates.previous}>
                    <TXDButton
                      className="bg-white uppercase px-4 py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 sm:w-[115px]"
                      radius="full"
                      variant="secondary"
                      type="button"
                      border={false}
                      onClick={() => {
                        goToStep(0);
                        setButtonStates({
                          cancel: false,
                          next: false,
                          previous: true,
                          save: true,
                        });
                      }}
                    >
                      Previous
                    </TXDButton>
                  </TXDBox>
                  <TXDBox hidden={buttonStates.save}>
                    <TXDButton
                      className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                      radius="full"
                      variant="primary"
                      type="submit"
                    >
                      {loading ? <SaveSpinner /> : "SAVE"}
                    </TXDButton>
                  </TXDBox>
                </TXDFlex>
              </TXDBox>
            </TXDBox>
          </form>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default AddNewParticipantModal;
