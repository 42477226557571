import React from "react";
import NavHeader from "@/components/NavHeader";
import PageWrapper from "@/components/PageWrapper";
import Footer from "@/components/Footer";
import AdministratorTableList from "@/components/AdministratorTable";
import CompanyTile from "@/components/CompanyTile";
import SponsorsTable from "@/components/SponsorsTable";
import ParticipantsTable from "@/components/ParticipantsTable";
import { findComponent, findPermissionList } from "@/utils/pageUtils";
import PageShimmer from "@/components/PageShimmer";
import { IThirdPartyAdministratorDashboard } from "@/interfaces";
import getPagePermissionList from "@/services/getPagePermission";
import { rolePageId, SiteId } from "@/constants/constant";
import InfoTile from "@/components/InfoTile";
import ReminderCard from "@/components/ReminderCard";
import AdvisorCard from "@/components/AdvisorCard";
import TransacationTable from "@/components/TransacationTable";

const ThirdPartyAdministratorDashboard: React.FC<
  IThirdPartyAdministratorDashboard
> = ({ data }) => {
  const pageData = getPagePermissionList(SiteId, rolePageId.TPADashboard);

  if (!pageData || pageData.SectionList.length === 0 || !data.userProfile) {
    return <PageShimmer data={data} />;
  }

  const renderComponent = (
    componentId: any,
    Component: any,
    additionalProps = {}
  ) => {
    if (findComponent(componentId, pageData)) {
      return (
        <Component
          componentPermissions={findPermissionList(componentId, pageData)}
          data={data}
          {...additionalProps}
        />
      );
    }
    return null;
  };

  return (
    <>
      <PageWrapper>
        <NavHeader data={data} />
        {renderComponent(139, CompanyTile)}
        <InfoTile>
          {renderComponent(138, AdministratorTableList)}
          {renderComponent(106, ReminderCard)}
          {renderComponent(156, AdvisorCard)}
        </InfoTile>
        {renderComponent(109, SponsorsTable)}
        {renderComponent(136, TransacationTable)}
        {renderComponent(150, ParticipantsTable)}
      </PageWrapper>
      <Footer />
    </>
  );
};

export default ThirdPartyAdministratorDashboard;
