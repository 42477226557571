import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDSelect,
} from "@savedaily/txd-components";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import "../../styles/index.css";
import {
  PORTFOLIO_ADDRESS_ADD_RQ,
  PORTFOLIO_CONTACT_MOD_RQ,
} from "@/services/api/userService";
import { IEditSponsorModalProps } from "@/interfaces";
import SaveSpinner from "../skeletons/SaveSpinner";
import { BUSINESS_TYPES, STATE_PROVINCE } from "@/constants/enumConstants";
import { loadGoogleMapsScript, validateAge } from "@/utils/componentUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "../skeletons/toastUtils";
import { API_BODY_CONSTANTS } from "@/constants/constant";
const EditAdvisorModal: React.FC<IEditSponsorModalProps> = ({
  modalOpen,
  closeModal,
  onUpdate,
  userProfile,
}: any) => {
  const administrator = userProfile[0];
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const [currentValue, setCurrentValues] = useState<any>({
    fullAddress: administrator.PortfolioAddress1,
    city: administrator.PortfolioCity,
    country: administrator.PortfolioCountry,
    postalCode: administrator.PortfolioPostalCode,
    stateProvince: administrator.PortfolioStateProv,
  });

  const accessCode = useSelector(
    (state: RootState) => state.auth.AccessCode as string
  );
  useEffect(() => {
    if (modalOpen) {
      const initializeGoogle = async () => {
        try {
          await loadGoogleMapsScript("address", setCurrentValues);
        } catch (error) {
          // console.log(error);
        }
      };
      initializeGoogle();
    }
  }, [modalOpen, currentValue.fullAddress]);
  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
    input9: false,
    input10: false,
  });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const streetAddress2 = e.target[7].value;
    const stateProvincs = e.target[8].value;
    const city = e.target[9].value;
    const zipCode = e.target[10].value;
    const phoneNo = e.target[11].value;
    const email = e.target[12].value;
    const formattedPhone = phoneNo.replace(/\D/g, "");
    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.INPUTS_ERROR);
      setLoading(false);
      return;
    } else {
      toast
        .promise(
          Promise.all([
            await PORTFOLIO_ADDRESS_ADD_RQ(
              {
                PortfolioPermId: administrator.PortfolioPermId,
                Addr1: currentValue.fullAddress,
                Addr2: administrator?.PortfolioAddress2 || streetAddress2,
                City: city,
                StateProv: stateProvincs,
                PostalCode: zipCode ? zipCode : null,
                Country: administrator?.PortfolioCountry || "",
                AddressTypePermId: API_BODY_CONSTANTS.ADDRESS_TYPE_PERM_ID,
                IsPrimary: true,
              },
              accessCode
            ),
            await PORTFOLIO_CONTACT_MOD_RQ(
              {
                PortfolioPermId: administrator.PortfolioPermId,
                EmailAddr: email,
                EmailAddr2: email || "",
                Phone: formattedPhone,
                PhoneType: formattedPhone.length,
              },
              accessCode
            ),
          ]),
          {
            loading: LOADING_MESSAGES.PROFILE_LOADING,
            success: <b>{SUCCESS_MESSAGES.PROFILE_ADDED}</b>,
            error: <b>{ERROR_MESSAGES.PROFILE_FAILED}</b>,
          }
        )
        .then(() => {
          onUpdate();
          closeModal();
          setLoading(false);
          location.reload();
        })
        .catch((error: any) => {
          toast.error(ERROR_MESSAGES.PROFILE_FAILED || error?.message);
          setLoading(false);
        });
    }
  };
  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errorMessage = validateAge(event.target.value);
    setDateError(errorMessage);
    updateErrorState("dob", !!errorMessage);
  };
  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center "
          onClose={() => {
            //Do nothing
          }}
          data-testid="editAdminstratorModal"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <TXDBox className="inline-block  w-full max-w-2xl my-8 mx-12  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <TXDFlex
                justifyContent="center"
                className=" "
                alignItems="center"
              >
                <TXDBox className="modalOverlay w-full  ">
                  <TXDBox>
                    <TXDFlex
                      alignItems="center"
                      className="p-4 flex justify-between border-b"
                    >
                      <TXDText
                        align="left"
                        as="h1"
                        className="uppercase  text-gray-500 sm:text-xl text-md whitespace-nowrap  "
                        size="xl"
                        weight="normal"
                      >
                        Edit Administrator
                      </TXDText>
                      <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none"
                          onClick={closeModal}
                        />
                      </TXDBox>
                    </TXDFlex>
                  </TXDBox>
                  <TXDFlex
                    className="overflow-y-auto  p-4 participant-table"
                    style={{ maxHeight: "calc(100vh - 160px)" }}
                  >
                    <form
                      className="w-full"
                      id="profileForm"
                      onSubmit={handleSubmit}
                      onKeyDown={handleKeyDown}
                    >
                      <TXDInput
                        id="firstName"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="First Name"
                        placeholder="Enter first name"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={REGEX_CONSTANTS.FIRST_NAME_REGEX}
                        minLength={1}
                        maxLength={30}
                        required
                        errorExist={(error: any) =>
                          updateErrorState("input1", error ? true : false)
                        }
                        initialValue={administrator.PortfolioFirstName || ""}
                        filled={true}
                        disabled
                      />
                      <TXDInput
                        id="lastName"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Last Name"
                        placeholder="Enter last name"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={REGEX_CONSTANTS.LAST_NAME_REGEX}
                        minLength={1}
                        maxLength={30}
                        required
                        errorExist={(error: any) =>
                          updateErrorState("input2", error ? true : false)
                        }
                        initialValue={administrator.PortfolioLastName || ""}
                        filled={true}
                        disabled
                      />
                      <TXDInput
                        id="legalName"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Company Name"
                        placeholder="Enter company name"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={REGEX_CONSTANTS.ADMINISTRATIVE_FIRM_REGEX}
                        minLength={3}
                        maxLength={200}
                        initialValue={administrator?.PortfolioLegalName}
                        required
                        filled={true}
                        disabled
                      />
                      <TXDInput
                        id="taxId"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Tax ID"
                        placeholder="Enter tax ID"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        filled
                        regex={REGEX_CONSTANTS.TAX_ID_REGEX}
                        required
                        errorExist={(error) =>
                          updateErrorState("input4", error ? true : false)
                        }
                        initialValue={
                          administrator?.PortfolioTaxIdentifier || ""
                        }
                        disabled
                      />
                      <TXDInput
                        id="dob"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Date of Birth"
                        placeholder="YYYY-MM-DD"
                        sizing="xs"
                        type="date"
                        variant="styled"
                        filled
                        disabled
                        required
                        errorExist={(error) =>
                          updateErrorState(
                            "input5",
                            error || dateError ? true : false
                          )
                        }
                        initialValue={
                          administrator?.PortfolioBirthDate.slice(0, 10) || ""
                        }
                        regex={REGEX_CONSTANTS.DOB_REGEX}
                        max={new Date().toISOString().split("T")[0]}
                        onInput={handleDateChange}
                      />
                      {dateError && (
                        <TXDBox className="text-red-500 font-normal text-sm leading-[1.25rem]">
                          {dateError}
                        </TXDBox>
                      )}
                      <TXDBox hidden>
                        <TXDSelect
                          className="external-downimg pl-0"
                          id="selectCountry"
                          isObjectArray
                          label
                          defaultValue={""}
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Business Class"
                          sizing="xs"
                          variant="styled"
                          optionsArray={[
                            {
                              label: "-- Please select bussiness class --",
                              value: "",
                              disabled: true,
                            },
                            ...BUSINESS_TYPES.map((business) => ({
                              label: business.label,
                              value: business.value,
                            })),
                          ]}
                        />
                      </TXDBox>

                      <TXDInput
                        id="address"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Street Address"
                        placeholder="Enter street address"
                        sizing="xs"
                        // type="text"
                        variant="styled"
                        required
                        value={currentValue.fullAddress}
                        onInput={(e: any) => {
                          setCurrentValues({
                            ...currentValue,
                            fullAddress: e.target.value || "",
                          });
                          // setAddressValue(e.currentTarget.value);
                        }}
                        errorExist={(error: any) =>
                          updateErrorState("input7", error ? true : false)
                        }
                        // initialValue={administrator.PortfolioAddress1}
                      />
                      <TXDInput
                        id="streetAddress2"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Street Address 2"
                        placeholder="Enter street address 2 (Optional)"
                        sizing="xs"
                        variant="styled"
                        className="pl-0"
                        errorExist={(error: any) =>
                          updateErrorState("input8", error ? true : false)
                        }
                        initialValue={administrator?.PortfolioAddress2 || ""}
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap mt-1 mb-1"
                      >
                        (address line 2)
                      </TXDText>
                      <TXDSelect
                        className="external-downimg pl-0"
                        id="stateProvince"
                        isObjectArray
                        label
                        onChange={(e: any) => {
                          setCurrentValues({
                            ...currentValue,
                            stateProvince: e.target.value || "",
                          });
                        }}
                        value={currentValue.stateProvince}
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="State or Province"
                        optionsArray={STATE_PROVINCE["US"].map((state) => ({
                          label: state.name || state.label,
                          value: state.state_code || state.value,
                          disabled: state.disabled,
                        }))}
                        sizing="xs"
                        variant="styled"
                        required
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap mt-1 mb-1"
                      >
                        (no abbreviations)
                      </TXDText>
                      <TXDInput
                        id="selectCity"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer text-xs "
                        variant="styled"
                        className="pl-0"
                        labelText={"City"}
                        placeholder="Enter city name"
                        type="text"
                        sizing="xs"
                        value={currentValue.city}
                        onInput={(e: any) => {
                          setCurrentValues({
                            ...currentValue,
                            city: e.target.value || "",
                          });
                        }}
                        minLength={2}
                        maxLength={30}
                        regex={REGEX_CONSTANTS.CITY_REGEX}
                        errorExist={(error: any) =>
                          updateErrorState("input10", error ? true : false)
                        }
                        required
                      />
                      <TXDInput
                        id="zipCode"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Zip or Postal Code"
                        placeholder="Enter zip or postal code"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={REGEX_CONSTANTS.ZIP_CODE_REGEX}
                        required
                        value={currentValue.postalCode}
                        onInput={(e: any) => {
                          setCurrentValues({
                            ...currentValue,
                            postalCode: e.target.value || "",
                          });
                        }}
                        errorExist={(error: any) =>
                          updateErrorState("input11", error ? true : false)
                        }
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap mt-1 mb-1"
                      >
                        (or Postal Code)
                      </TXDText>

                      <TXDInput
                        id="phoneNumber"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Phone Number"
                        placeholder="Enter phone number"
                        sizing="xs"
                        type="tel"
                        variant="styled"
                        regex={REGEX_CONSTANTS.PHONE_REGEX}
                        required
                        errorExist={(error: any) =>
                          updateErrorState("input12", error ? true : false)
                        }
                        initialValue={administrator.PortfolioPhone || ""}
                      />
                      <TXDInput
                        id="email"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs pb-44"
                        labelText="Email Address"
                        placeholder="Enter email address"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={REGEX_CONSTANTS.EMAIL_REGEX}
                        required
                        errorExist={(error) =>
                          updateErrorState("input13", error ? true : false)
                        }
                        initialValue={
                          administrator.PortfolioEmailAddress1 || ""
                        }
                      />
                      <TXDFlex
                        justifyContent="end"
                        className="gap-4 px-4 py-4 bg-white fixed bottom-0 left-0 right-0 flex-col-reverse sm:flex-row z-30"
                      >
                        <TXDButton
                          className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 sm:w-[115px]"
                          radius="full"
                          // size="md"
                          variant="secondary"
                          border={false}
                          onClick={closeModal}
                        >
                          Cancel
                        </TXDButton>
                        <TXDButton
                          className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                          radius="full"
                          // size="lg"
                          variant="primary"
                          type="submit"
                        >
                          {loading ? <SaveSpinner /> : "SAVE"}
                        </TXDButton>
                      </TXDFlex>
                    </form>
                  </TXDFlex>
                </TXDBox>
              </TXDFlex>
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default EditAdvisorModal;
