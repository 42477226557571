import React, { useState } from "react";
import { TXDBox, TXDFlex, TXDImage, TXDText } from "@savedaily/txd-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import crossImage from "../assets/images/cross.png";
const QuickAccessButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const togglePanel = () => {
    setIsOpen(!isOpen);
  };
  const handleNavigation = (path: any) => {
    navigate(path);
    setIsOpen(false);
  };
  return (
    <>
      {isOpen && (
        <TXDBox
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsOpen(false)}
        ></TXDBox>
      )}
      <TXDBox
        className={`fixed top-0 sm:top-[25%] right-0 sm:right-[3px]  w-full sm:w-[360px] h-full  transform transition-transform duration-300 ease-in-out z-40
         ${isOpen ? "translate-x-0" : "translate-x-full"}`}
      >
        {isOpen && (
          <TXDBox className="flex justify-between relative ">
            <button
              className={`bg-[#6EBE45] leading-7 opacity-[90%] text-md font-semibold text-white text-center rounded-t-lg shadow-lg flex items-center  sm:w-[150px] w-full  pl-[15px]`}
            >
              &#9733; Quick Access
            </button>
            <TXDImage
              src={crossImage}
              className="border-none text-xl font-normal sm:w-[15px] sm:h-[15px] w-[10px] h-[10px] cursor-pointer hover:text-[#01B5E1]  focus:outline-none absolute right-0 top-[6px] sm:top-7 z-40 mr-2"
              onClick={togglePanel}
            />
          </TXDBox>
        )}
        {isOpen && (
          <TXDBox className="content bg-white rounded-b-sm shadow-lg z-40 h-full sm:h-[70px]  pt-3">
            <TXDBox className="border-b border-[#EFEFEF]">
              <TXDFlex
                onClick={() => handleNavigation("/documents")}
                className="quick-access p-3  cursor-pointer "
              >
                <TXDText
                  as="span"
                  className="text-[16px] font-normal text-[#000000]"
                >
                  Documents
                </TXDText>
                <FontAwesomeIcon icon={faArrowRight} />
              </TXDFlex>
            </TXDBox>
          </TXDBox>
        )}
      </TXDBox>
      <button
        className={`fixed right-0 top-1/3 transform -translate-y-1/2 rotate-180 w-[35px] h-[142px] opacity-[90%] bg-[#6EBE45] leading-7 text-md font-semibold text-white text-center rounded-r-lg shadow-lg flex items-center justify-center z-50 ${
          isOpen ? "hidden" : ""
        }`}
        style={{
          writingMode: "vertical-lr",
          textOrientation: "mixed",
        }}
        onClick={togglePanel}
      >
        &#9733; Quick Access
      </button>
    </>
  );
};

export default QuickAccessButton;
