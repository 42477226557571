import { TXDBox, TXDFlex, TXDImage } from "@savedaily/txd-components";
import React from "react";
// logo-rollover.png
import rollOverLogo from "../assets/images/logo-rollover.png";
const Footer: React.FC = () => {
  // bg-[#00283A]
  return (
    <footer
      style={{ zIndex: 7 }}
      className="bg-[#00283A] fixed bottom-0 w-full"
    >
      <TXDBox className="w-full mx-auto px-4 lg:px-6 py-6">
        <TXDBox className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-8">
          <TXDFlex className="gap-6 items-center">
            <TXDImage
              src={rollOverLogo}
              alt="save-daily-logo"
              className="w-40"
            />
            <p className="text-sm leading-6 text-white hover:[#686868]">
              ©2024 All Rights Reserved. SaveDaily.com Inc.
              {/* is an SEC Registered Investment Advisor. */}
            </p>
          </TXDFlex>

          <ul className="flex justify-end items-center text-white hover:[#686868] gap-x-3">
            <li className="text-sm">
              <a href="">Privacy</a>
            </li>
            |
            <li className="text-sm">
              <a href="">Terms</a>
            </li>
          </ul>
        </TXDBox>
      </TXDBox>
    </footer>
  );
};

export default Footer;
