import NavHeader from "@/components/NavHeader";
import PageWrapper from "@/components/PageWrapper";
import React from "react";
import Footer from "@/components/Footer";
import ReminderCard from "@/components/ReminderCard";
import CompanyTile from "@/components/CompanyTile";
import SponsorsTable from "@/components/SponsorsTable";
import ParticipantsTable from "@/components/ParticipantsTable";
import { findComponent, findPermissionList } from "@/utils/pageUtils";
import AdministratorCard from "@/components/AdministratorCard";
import PageShimmer from "@/components/PageShimmer";
import { IPlanAdministratorDashboard } from "@/interfaces";
import getPagePermissionList from "@/services/getPagePermission";
import { rolePageId, SiteId } from "@/constants/constant";
import InfoTile from "@/components/InfoTile";
import AdministratorTableList from "@/components/AdministratorTable";
import TransacationTable from "@/components/TransacationTable";

const PlanAdministratorDashboard: React.FC<IPlanAdministratorDashboard> = ({
  data,
}) => {
  const pageData = getPagePermissionList(SiteId, rolePageId.PADashboard);
  // console.log(pageData);
  if (!pageData || pageData.SectionList.length === 0 || !data.userProfile) {
    return <PageShimmer data={data} />;
  }

  const renderComponent = (
    componentName: any,
    Component: any,
    additionalProps = {}
  ) => {
    if (findComponent(componentName, pageData)) {
      return (
        <Component
          componentPermissions={findPermissionList(componentName, pageData)}
          data={data}
          {...additionalProps}
        />
      );
    }
    return null;
  };

  return (
    <>
      <PageWrapper>
        <NavHeader data={data} />
        {renderComponent(156, CompanyTile)}
        <InfoTile>
          {renderComponent(139, AdministratorCard)}
          {renderComponent(106, ReminderCard)}
          {renderComponent(138, AdministratorTableList)}
        </InfoTile>
        {renderComponent(109, SponsorsTable)}
        {renderComponent(136, TransacationTable)}
        {renderComponent(150, ParticipantsTable)}
      </PageWrapper>
      <Footer />
    </>
  );
};

export default PlanAdministratorDashboard;
