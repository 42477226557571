import React, { useState } from "react";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDTable,
  TXDInput,
} from "@savedaily/txd-components";
import useFetchReminders from "@/services/useFetchReminders";
import { IReminderCardProps } from "@/interfaces";
import TabsSkeleton from "@/layouts/skeletons/TabsSkeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { REMINDER_ENUMS } from "@/constants/enumConstants";
import useCompleteTask from "@/services/useCompleteTask";
import useArchivedTask from "@/services/useArchivedTask";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "@/layouts/skeletons/toastUtils";

const ReminderCard: React.FC<IReminderCardProps> = ({
  componentPermissions,
}) => {
  const [selectedTask, setSelectedTask] = useState(null);

  const [selectedButton, setSelectedButton] = useState<any>(
    REMINDER_ENUMS.TODO.value
  );
  const { reminders, loading } = useFetchReminders(selectedButton);
  const { completeTask } = useCompleteTask();
  const { archiveTask } = useArchivedTask();
  const { permissionsList } = componentPermissions;

  const handleClick = async (buttonName: any) => {
    setSelectedButton(buttonName);

    // await fetchReminders();
  };
  const handleTaskSelection = (taskId: any) => {
    setSelectedTask(taskId);
  };
  const handleCompleteTask = async () => {
    if (selectedTask) {
      try {
        await completeTask(selectedTask);
        // await fetchReminders(REMINDER_ENUMS.COMPLETED.value);
        setSelectedButton(REMINDER_ENUMS.COMPLETED.value);
        setSelectedTask(null);
      } catch (error: any) {
        toast.error(ERROR_MESSAGES.COMP_TASK_FAILED || error.message);
      }
    }
  };
  const handleArchivedTask = async () => {
    if (selectedTask) {
      try {
        await archiveTask(selectedTask);
        setSelectedButton(REMINDER_ENUMS.ARCHIVED.value);
        setSelectedTask(null);
      } catch (error: any) {
        toast.error(ERROR_MESSAGES.ARC_TASK_FAILED || error.message);
      }
    }
  };
  const formatSubject = (subject: any) => {
    const dateTimePattern =
      /(\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [APM]{2})/g;
    const datePattern = /(\d{1,2}\/\d{1,2}\/\d{4})/g;

    // Remove date-time matches
    let formattedSubject = subject.replace(dateTimePattern, "");

    // Remove remaining date matches
    formattedSubject = formattedSubject.replace(datePattern, "");

    // Remove any extra spaces or punctuation left behind
    formattedSubject = formattedSubject.replace(/  +/g, " ");
    formattedSubject = formattedSubject.replace(/ ,/g, "");
    formattedSubject = formattedSubject.trim();

    return formattedSubject;
  };
  const formatTaskData = (data: any[]) => {
    if (!Array.isArray(data)) return [];
    return data.map((reminder) => ({
      task: (
        <TXDFlex
          alignItems="center"
          data-sort={formatSubject(reminder.Subject)}
        >
          <TXDInput
            type="radio"
            className="w-3 h-3 mr-2  text-[#4EC6E5] border-[#A9A9A9] cursor-pointer"
            checked={selectedTask === reminder.ToDoPermId}
            onChange={() => handleTaskSelection(reminder.ToDoPermId)}
          />

          {/* <TXDBox className="w-3 h-3 mr-2 rounded-full border-2 border-[#A9A9A9] bg-[#F8F8F8]" /> */}
          <TXDText
            as="h4"
            className="text-gray-500 block"
            size="sm"
            weight="medium"
          >
            {formatSubject(reminder.Subject)}
          </TXDText>
        </TXDFlex>
      ),
      dueDate: (
        <TXDText
          data-sort={new Date(reminder.AddDt).toISOString().split("T")[0]}
          as="h4"
          className="text-gray-500 block"
          size="sm"
          weight="medium"
        >
          {new Date(reminder.AddDt).toISOString().split("T")[0]}
        </TXDText>
      ),
    }));
  };

  const tableData = formatTaskData(reminders);
  // console.log("This is table data => ", tableData);
  return (
    <>
      {permissionsList.read ? (
        <TXDBox className="shadow-lg bg-[#ffffff] pb-4 relative">
          <TXDFlex className="company-profile">
            <TXDText
              align="left"
              as="h1"
              className="uppercase w-full border-l-4 border-l-[#4EC6E5] py-4 pl-4 pr-20 font-sm text-gray-500"
              size="xl"
              weight="normal"
            >
              REMINDERS
            </TXDText>
          </TXDFlex>

          {/* Tabs */}
          <TXDFlex className="sm:flex-row sm:text-start text-center p-[6px] bg-[#F3F4F6] gap-2 border-gray-200 border-b-2">
            <TXDBox>
              <TXDButton
                className={`${
                  selectedButton == REMINDER_ENUMS.TODO.value
                    ? "bg-[#A2B4C7] text-white"
                    : "bg-white text-[#6d839b]"
                } px-7 py-1 uppercase shadow-sm text-sm ring-1 ring-[#A2B4C7] hover:bg-[#A2B4C7] hover:text-white hover:ring-[#A2B4C7] leading-4	`}
                radius="full"
                size="sm"
                onClick={() => handleClick(REMINDER_ENUMS.TODO.value)}
              >
                To Do
              </TXDButton>
            </TXDBox>
            <TXDBox>
              <TXDButton
                className={`${
                  selectedButton == REMINDER_ENUMS.COMPLETED.value
                    ? "bg-[#A2B4C7] text-white"
                    : "bg-white text-[#6d839b]"
                } px-7 py-1 uppercase shadow-sm text-sm ring-1 ring-[#A2B4C7] hover:bg-[#A2B4C7] hover:text-white hover:ring-[#A2B4C7] leading-4	`}
                radius="full"
                size="sm"
                onClick={() => handleClick(REMINDER_ENUMS.COMPLETED.value)}
              >
                Completed
              </TXDButton>
            </TXDBox>
            <TXDBox>
              <TXDButton
                className={`${
                  selectedButton == REMINDER_ENUMS.ARCHIVED.value
                    ? "bg-[#A2B4C7] text-white"
                    : "bg-white text-[#6d839b]"
                } px-7 py-1 uppercase shadow-sm text-sm ring-1 ring-[#A2B4C7] hover:bg-[#A2B4C7] hover:text-white hover:ring-[#A2B4C7] leading-4	`}
                radius="full"
                size="sm"
                onClick={() => handleClick(REMINDER_ENUMS.ARCHIVED.value)}
              >
                Archived
              </TXDButton>
            </TXDBox>
          </TXDFlex>
          <TXDBox className="grid pb-20 border  border-[#f9f9f9] grid-cols-1 overflow-hidden bg-white sm:grid-cols-1">
            {loading ? (
              <TabsSkeleton />
            ) : (
              <TXDBox>
                <TXDTable
                  tRow={tableData}
                  thStyle="p-1"
                  filteration
                  pagination={tableData.length > 0 ? true : false}
                  tHead={tableData.length > 0 ? ["Task Name", "Date"] : []}
                  title=""
                  startEntries={5}
                />
              </TXDBox>
            )}
          </TXDBox>
          {/* {tableData.length > 0 ? ( */}
          <TXDFlex
            className="w-full px-4 gap-4 absolute bottom-[1.85rem]"
            justifyContent="end"
          >
            <TXDButton
              className="bg-white !text-[#4EC6E5] font-semibold uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5]  text-medium"
              radius="none"
              variant="primary"
              border={false}
              onClick={handleCompleteTask}
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Complete
            </TXDButton>

            <TXDButton
              className="bg-white !text-[#4EC6E5] font-semibold uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5]  text-medium"
              radius="none"
              variant="primary"
              border={false}
              onClick={handleArchivedTask}
            >
              <FontAwesomeIcon icon={faArchive} /> ARCHIVE
            </TXDButton>
            {/* </TXDBox> */}
          </TXDFlex>
          {/* ) : (
            <></>
          )} */}
        </TXDBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default ReminderCard;
