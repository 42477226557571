import React from "react";
import { TXDBox, TXDFlex } from "@savedaily/txd-components";
import NavLeftSection from "./NavLeftSection";
import NavRightSection from "./NavRightSection";
import NavRightSkeleton from "@/layouts/skeletons/NavRightSkeleton";
import { INavHeader } from "@/interfaces";

const NavHeader: React.FC<INavHeader> = ({ data, pageId }) => {
  if (data.userProfile === null) {
    return (
      <TXDBox className="sticky grid grid-cols-12 top-0 bg-white shadow-lg z-10">
        <NavLeftSection data={data} />
        <NavRightSection data={data} />
      </TXDBox>
    );
  } else {
    return (
      // sticky top-0 flex flex-col xl:flex-row lg:flex-row md:flex-col sm:flex-col my-0 mx-0 bg-[#FAFAFA] border-solid border-b-2 border-[#f4f4f4] max-h-auto
      <TXDBox className="sticky grid grid-cols-12 top-0 bg-white shadow-lg z-10">
        <NavLeftSection data={data} pageId={pageId} />
        <NavRightSection data={data} />
      </TXDBox>
    );
  }
};

export default NavHeader;
