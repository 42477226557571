import React, { useState, useRef, useEffect } from "react";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDImage,
  TXDInput,
} from "@savedaily/txd-components";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import profileIcon from "../assets/images/profileicon.png";
import { AppDispatch } from "@/redux/store";
import { loginUser } from "@/services/LoginUser";
import SaveSpinner from "@/layouts/skeletons/SaveSpinner";
import { useCookies } from "react-cookie";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
const LogIn: React.FC = () => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const textRef = useRef(null);
  const [cookies, setCookie] = useCookies(["IdentityToken", "Username"]);

  useEffect(() => {
    //
  }, [setCookie]);
  const validateUseName = (value: string) => {
    setUserName(value);
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const identityToken =
        cookies.Username === userName ? cookies.IdentityToken : null;
      if (userName && password) {
        await dispatch(
          loginUser(userName, password, navigate, identityToken, cookies)
        );
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (value: string) => {
    setPassword(value);
  };

  return (
    <>
      <TXDFlex
        variant="column"
        justifyContent="center"
        className="flex min-h-full flex-1 px-6 lg:px-8 bg-[#FAFAFA]"
      >
        <TXDBox className="sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-lg shadow-lg p-10 mt-12">
          <TXDImage
            className="mx-auto h-20 w-20"
            src={profileIcon}
            alt="Profile icon"
          />
          <TXDText
            ref={textRef}
            as="span"
            align="center"
            size="xl"
            weight="bold"
            className="text-center flex justify-center py-4  text-gray-900"
          >
            Sign in to your account
          </TXDText>
          <form className="space-y-6" method="POST" onSubmit={handleLogin}>
            <TXDBox>
              <label
                htmlFor="name"
                className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              >
                Username
              </label>
              <TXDBox className="relative mt-2">
                <TXDInput
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="username"
                  className="pl-0 placeholder:text-sm"
                  required
                  variant={"styled"}
                  placeholder="Username"
                  regex={REGEX_CONSTANTS.USERNAME_REGEX}
                  onBlur={(e: any) => validateUseName(e.target.value)}
                  onInput={(e: any) => validateUseName(e.target.value)}
                />
              </TXDBox>
            </TXDBox>

            <TXDBox>
              <TXDFlex className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                >
                  Password
                </label>
                {/* <TXDText className="text-sm">
                  <Link
                    to="#"
                    className="font-semibold text-[#15B6DB] hover:text-[#137B8D]"
                  >
                    Forgot Username?
                  </Link>
                </TXDText> */}
              </TXDFlex>
              <TXDBox className="relative mt-2">
                <TXDInput
                  variant={"styled"}
                  id="password"
                  className="pl-0 placeholder:text-sm"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  placeholder="Enter Password"
                  required
                  value={password}
                  onBlur={(e: any) => validatePassword(e.target.value)}
                  onInput={(e: any) => validatePassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setShowPassword((prev) => !prev)}
                  data-testid="toggle-password-visibility"
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
              </TXDBox>
              <TXDFlex className="flex items-center justify-end">
                <TXDText className="text-sm">
                  <Link
                    to="/forgot-password"
                    className="font-semibold text-[#15B6DB] hover:text-[#137B8D]"
                  >
                    Forgot password?
                  </Link>
                </TXDText>
              </TXDFlex>
            </TXDBox>

            <TXDBox className="text-center">
              <TXDButton
                className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                radius="full"
                variant="primary"
                type="submit"
              >
                {loading ? <SaveSpinner /> : "Sign in"}
              </TXDButton>
            </TXDBox>
          </form>

          <TXDText
            size="sm"
            weight="normal"
            className="mt-6 flex justify-center flex-col items-center text-center text-gray-500"
          >
            <Link
              to="/start-rollover"
              className="font-semibold leading-6 text-[#15B6DB] hover:text-[#137B8D]"
            >
              Registration
            </Link>
          </TXDText>
        </TXDBox>
      </TXDFlex>
    </>
  );
};

export default LogIn;
