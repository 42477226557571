import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faListCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  TXDFlex,
  TXDBox,
  TXDText,
  TXDImage,
  TXDButton,
} from "@savedaily/txd-components";
import Sidebar from "./Sidebar";
import profileImage from "../assets/images/profile.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { userPolicyId } from "@/constants/constant";
import { INavLeftSection } from "@/interfaces";

const NavLeftSection: React.FC<INavLeftSection> = ({ data, pageId }) => {
  const { userProfile } = data;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const userPolicy = useSelector((state: RootState) => state.auth.policy);
  const userLoginName = useSelector((state: RootState) => state.auth.LoginName);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const firstName =
    userProfile === null
      ? ""
      : userProfile?.InternalPortfolio?.PortfolioFirstName || "";
  const lastName = userProfile?.InternalPortfolio?.PortfolioLastName || "";
  const legalName = userProfile?.Portfolio?.LegalName || "";

  const displayName =
    firstName || lastName ? `${firstName} ${lastName}`.trim() : legalName;

  return (
    <TXDBox className="grid grid-cols-9 col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-5 bg-[#FFFFFF] border-[#f4f4f4] border-b-2 md:border-l-0 md:border-t-0 md:border-b-0 md:border-r-2">
      <TXDFlex
        variant="row"
        justifyContent="center"
        alignItems="center"
        className="col-span-1 bg-[#00283A] z-10 cursor-pointer"
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon
          icon={isSidebarOpen ? faXmark : faBars}
          className="h-6 w-8 text-white transition-transform duration-300 ease-in-out "
          style={{ transform: `rotate(${isSidebarOpen ? 90 : 0}deg)` }}
        />
      </TXDFlex>
      <Sidebar sidebarActive={isSidebarOpen} data={data} pageId={pageId} />
      <TXDFlex
        variant="row"
        alignItems="center"
        className="col-span-5 sm:col-span-4 md:col-span-5 lg:col-span-5 px-6 sm:px-8 md:px-8   lg:px-10 xl:px-12 py-3 sm:py-3 gap-1"
      >
        <TXDBox>
          <TXDImage
            display="inline-block"
            radius="full"
            size="xss"
            src={profileImage}
            variant="cover"
            className="h-14 sm:h-14 md:h-12 lg:h-14 xl:h-14 w-14 sm:w-14 md:w-12 lg:w-14 xl:w-14"
          />
        </TXDBox>
        <TXDFlex variant="column" alignContent="center">
          <TXDFlex variant="row">
            <TXDText
              as="span"
              className="text-gray-900 uppercase"
              size="xs"
              weight="bold"
            >
              Welcome
            </TXDText>
            {/* <TXDText
              as="span"
              className={`ml-2 relative text-gray-400 cursor-pointer ${
                isSidebarOpen ? "hidden" : ""
              }`}
            >
              <a href="mailto:support@savedaily.com">
                <TXDImage src={messageIcon} />
              </a>
              <TXDText
                as="span"
                className="animate-bounce border m-0 p-1 cursor-pointer absolute -top-3 -right-3 text-white bg-[#00AFD8] border-[#00AFD8] text-[10px] rounded-full"
                style={{
                  lineHeight: "12px",
                }}
              >
                13
              </TXDText>
            </TXDText> */}
          </TXDFlex>
          <TXDText
            as="span"
            className="text-gray-500 text-sm sm:text-sm md:text-md lg:text-xl xl:text-xl whitespace-nowrap"
            size="xl"
            weight="normal"
          >
            {userLoginName ? userLoginName : "--"}
          </TXDText>
        </TXDFlex>
      </TXDFlex>
      {userPolicy?.PolicyId === userPolicyId.PSPolicy ? (
        <></>
      ) : (
        <TXDFlex
          variant="column"
          justifyContent="center"
          alignItems="end"
          className="col-span-3 sm:col-span-4 md:col-span-3 lg:col-span-3 gap-1 px-4"
        >
          <TXDBox>
            <Link to={"/reminders"}>
              <TXDText className="inline-flex rounded-md w-full">
                <TXDButton className="inline-flex items-center gap-x-1.5 rounded-l-full bg-[#33C4A5] hover:bg-[#38D6B5] text-white ring-1 ring-inset ring-[#33C4A5] hover:ring-[#33C4A5] text-[12px] px-5 sm:px-5 md:px-5 lg:px-6 xl:px-6 py-[4px] sm:py-2 md:py-2 lg:py-2 xl:py-2 sm:text-xs font-semibold uppercase">
                  REMINDERS
                </TXDButton>
                <FontAwesomeIcon
                  icon={faListCheck}
                  className="inline-flex items-center rounded-r-full text-[12px] bg-[#38D6B5] hover:bg-[#33C4A5] text-white ring-1 ring-inset ring-[#38D6B5] hover:ring-[#38D6B5] px-2 sm:px-3 md:px-2 lg:px-3 xl:px-3 py-[8px] sm:py-2 md:py-2 lg:py-2 xl:py-2 text-lg font-bold"
                />
              </TXDText>
            </Link>
          </TXDBox>
        </TXDFlex>
      )}
    </TXDBox>
  );
};

export default NavLeftSection;
