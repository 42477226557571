/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import profileImage from "../../assets/images/profile.png";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDImage,
  TXDInput,
  TXDSelect,
} from "@savedaily/txd-components";
import { INTERNAL_PARTICIPANT_ADD_REQ } from "@/services/api/userService";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { fetchSponsors } from "@/redux/slices/sponsorSlice";
import { updateField, resetForm } from "@/redux/slices/participantFormSlice";
import SaveSpinner from "../skeletons/SaveSpinner";
import {
  MARTIAL_STATUS,
  OCCUPATION_FIELD,
  STATE_PROVINCE,
  STATUS_CODE_MAP,
} from "@/constants/enumConstants";
import { loadGoogleMapsScript, validateAge } from "@/utils/componentUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
import { IAddParticipantModalProps } from "@/interfaces";

const MainInformationModal: React.FC<IAddParticipantModalProps> = ({
  closeModal,
  modalOpen,
  sponsorId,
  onParticipantAdded,
}) => {
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const AccessCode = useSelector((state: RootState) => state.auth.AccessCode);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedSponsor, setSelectedSponsor] = useState("");
  const [selectedSponsorId, setSelectedSponsorId] = useState<any>("");
  const [sponsorErrors, setSponsorErrors] = useState<string[]>([]);
  const { sponsors, sponseorsloading } = useSelector(
    (state: RootState) => state.sponsors
  );

  const defaultSponsorId = () => {
    const sponsor = sponsors.find(
      (s) => s.PortfolioPermId === parseInt(sponsorId)
    );

    if (sponsor) {
      dispatch(
        updateField({
          field: "sponsor",
          value: sponsor.PortfolioPermId.toString(),
        })
      );
      return sponsor.PortfolioPermId.toString();
    } else {
      return "";
    }
  };

  const activeSponsors = sponsors.filter(
    (sponosrPortfolio: any) => sponosrPortfolio.PortfolioStatus == 119
  );

  const participant = useSelector((state: RootState) => state.participantForm);
  // console.log("This is participant form => ", participant);

  const [currentIRACheckboxState, setIRACheckboxState] = useState(
    participant.RothIRA
  );
  const [currentTraditionalIRACheckboxState, setTraditionalIRACheckboxState] =
    useState(participant.TraditionalIRA);

  const portfolioPermId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const [currentValue, setCurrentValues] = useState<any>({
    fullAddress: "",
    city: "",
    country: "",
    postalCode: "",
    stateProvince: "",
  });

  const handleInputKeyUp =
    (field: keyof typeof participant) =>
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      dispatch(
        updateField({ field, value: (event.target as HTMLInputElement).value })
      );
    };

  const handleInputChange =
    (field: keyof typeof participant) =>
    (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      dispatch(updateField({ field, value: event.target.value }));
    };

  const handleInputBlur =
    (field: keyof typeof participant) =>
    (event: React.FocusEvent<HTMLInputElement>) => {
      dispatch(
        updateField({ field, value: (event.target as HTMLInputElement).value })
      );
    };

  if (!sponsors) return <p>No sponsors found.</p>;
  useEffect(() => {
    dispatch(fetchSponsors());
  }, [dispatch]);

  useEffect(() => {
    const initializeGoogle = async () => {
      try {
        await loadGoogleMapsScript("address", (val: any) => {
          dispatch(
            updateField({
              field: "streetAddress",
              value: val.fullAddress,
            })
          );
          dispatch(
            updateField({
              field: "zipCode",
              value: val.postalCode,
            })
          );
          dispatch(
            updateField({
              field: "city",
              value: val.city,
            })
          );

          dispatch(
            updateField({
              field: "Country",
              value: val.country,
            })
          );
          dispatch(
            updateField({
              field: "stateProvinces",
              value: val.stateProvince,
            })
          );
        });
      } catch (error) {
        // console.log(error);
      }
    };
    initializeGoogle();
  }, [modalOpen, participant.streetAddress]);

  const handleSelectChange = (value: string) => {
    const sponsor = sponsors.find((s) => s.PortfolioPermId === parseInt(value));

    if (sponsor) {
      setSelectedSponsor(
        `${sponsor.PortfolioFirstName} ${sponsor.PortfolioLastName}`
      );
      setSelectedSponsorId(sponsor.PortfolioPermId);
      setSponsorErrors([]);
    } else {
      setSponsorErrors(["Sponsor is required."]);
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errorMessage = validateAge(event.target.value);
    setDateError(errorMessage);
    updateErrorState("dob", !!errorMessage);
  };

  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
    input9: false,
    input10: false,
    input11: false,
    input12: false,
    input13: false,
    input14: false,
    input15: false,
    input16: false,
    input17: false,
    input18: false,
    input19: false,
    input20: false,
    input21: false,
    input22: false,
    input23: false,
    input24: false,
    input25: false,
  });

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const hasError =
    Object.values(inputErrors).some((error) => error) || !!dateError;

  if (hasError) {
    dispatch(
      updateField({
        field: "hasError",
        value: true,
      })
    );
  } else {
    dispatch(
      updateField({
        field: "hasError",
        value: false,
      })
    );
  }

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const sponsorPermId = e.target[0].value;
    const firstName = e.target[1].value;
    const middleName = e.target[2].value;
    const lastName = e.target[3].value;
    const taxId = e.target[4].value;
    const dob = e.target[5].value;
    const maritalStatus = e.target[6].value;
    const phoneNo = e.target[7].value;
    const email = e.target[8].value;
    const streetAddress = e.target[9].value;
    const streetAddress2 = e.target[10].value;
    const stateProvinces = e.target[11].value;
    const city = e.target[12].value;
    const zipCode = e.target[13].value;
    const occupationPermId = e.target[14].value;
    const RothIRAAmount = e.target[15].checked;
    const TraditionalRothIRAAmount = e.target[16].checked;
    const RMD = e.target[17].checked;
    const amountRothIRA = e.target[18].value;
    const amountTradistionalIra = e.target[19].value;
    const employeeCountry = e.target[20].value;
    const employeeName = e.target[21].value;
    const country = e.target[22].value;
    const nameSuffix = e.target[23].value;
    const nickName = e.target[24].value;
    const formattedPhone = phoneNo.replace(/\D/g, "");

    const apiData = {
      AgentPermId: portfolioPermId,
      SponsorPermId: Number(sponsorPermId),
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      TaxId: taxId,
      DOB: dob,
      Phone: formattedPhone,
      EmailAddr: email,
      Addr1: streetAddress,
      Addr2: streetAddress2,
      StateProv: stateProvinces,
      City: city,
      PhoneType: formattedPhone.length,
      PostalCode: zipCode,
      RMD: RMD,
      RothIRA: RothIRAAmount,
      TraditionalIRA: TraditionalRothIRAAmount,
      RothIRAAmount: RothIRAAmount ? amountRothIRA : "",
      TraditionalIRAAmount: TraditionalRothIRAAmount
        ? amountTradistionalIra
        : "",
      EmpCountry: employeeCountry,
      EmpName: employeeName,
      Country: country,
      OccupationPermId: occupationPermId,
    };

    // console.log("This is Api data => ", apiData);

    // Check if any input has an error

    if (hasError) {
      toast.error(
        "Error found in inputs. Please correct them before submitting."
      );
      setLoading(false);
      return;
    }
    if (RothIRAAmount || TraditionalRothIRAAmount) {
      try {
        const response = await INTERNAL_PARTICIPANT_ADD_REQ(
          AccessCode,
          apiData
        );
        if (response) {
          toast.success("Participant added successfully");
          if (onParticipantAdded) {
            onParticipantAdded();
          }
          closeModal();
          location.reload();
        } else {
          throw new Error("Invalid response from server");
        }
      } catch (error) {
        toast.error(String(error));
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please select at least one amount method");
    }
  };

  const handleCloseModal = () => {
    setSelectedSponsorId(null);
    dispatch(resetForm());
    closeModal();
  };

  return (
    <>
      <TXDBox className="sm:mt-6 mt-0 px-6">
        {/* 1st */}
        {/* <form
          className="w-full"
          id="profileForm"
          onSubmit={handleSubmit}
          onKeyDown={handleKeyDown}
        > */}
        <TXDFlex className=" flex-col md:flex-row md:justify-between w-full gap-6">
          {/* 2nd */}

          <TXDBox className="flex-1">
            <TXDBox className="flex-1 flex flex-col items-center justify-start">
              <TXDImage
                display="inline-block"
                className="w-36 h-36 rounded-full aspect-w-1 aspect-h-1"
                src={profileImage}
                variant="cover"
              />
            </TXDBox>
            {sponseorsloading ? (
              <p>Loading sponsors...</p>
            ) : (
              <TXDSelect
                id="selectSponsor"
                isObjectArray
                label
                labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                labelText="Sponsor"
                optionsArray={[
                  { label: "--Select a sponsor--", value: "" },
                  ...activeSponsors.map((sponsor) => ({
                    label: `${
                      sponsor.PortfolioLegalName
                        ? sponsor.PortfolioLegalName
                        : sponsor.PortfolioFirstName +
                          " " +
                          sponsor.PortfolioLastName
                    }`,
                    value: sponsor.PortfolioPermId.toString(),
                  })),
                ]}
                sizing="xs"
                variant="styled"
                className={`external-downimg text-gray-400 mb-1 pl-0${
                  sponsorErrors.length > 0 ? "border-b-2 border-red-500" : ""
                }`}
                // value={selectedSponsorId?.toString() || ""}
                value={selectedSponsorId?.toString() || defaultSponsorId()}
                defaultValue={""}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  // handleInputChange("sponsor");
                  // console.log("This is test  => ", e.target.value);
                  handleSelectChange(e.target.value);
                  dispatch(
                    updateField({
                      field: "sponsor",
                      value: e.target.value,
                    })
                  );
                }}
                required
              />
            )}
            <TXDInput
              id="firstName"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="First Name"
              placeholder="Enter first name"
              sizing="xs"
              type="text"
              variant="styled"
              regex={REGEX_CONSTANTS.FIRST_NAME_REGEX}
              minLength={1}
              maxLength={30}
              required
              errorExist={(error) =>
                updateErrorState("input2", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("firstName")}
              initialValue={participant.firstName}
            />
            <TXDInput
              id="middleName"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Middle Name"
              placeholder="Enter middle name (Optional)"
              sizing="xs"
              variant="styled"
              regex={REGEX_CONSTANTS.MIDDLE_NAME_REGEX}
              type="text"
              minLength={0}
              maxLength={30}
              errorExist={(error) =>
                updateErrorState("input3", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("middleName")}
              initialValue={participant.middleName}
            />
            <TXDInput
              id="contactLastName"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Last Name"
              placeholder="Enter last name"
              sizing="xs"
              type="text"
              variant="styled"
              regex={REGEX_CONSTANTS.LAST_NAME_REGEX}
              minLength={1}
              maxLength={30}
              required
              errorExist={(error) =>
                updateErrorState("input4", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("lastName")}
              initialValue={participant.lastName}
            />
            <TXDInput
              id="taxId"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Tax ID"
              placeholder="Enter Tax ID"
              sizing="xs"
              type="text"
              variant="styled"
              regex={REGEX_CONSTANTS.TAX_ID_REGEX}
              required
              errorExist={(error) =>
                updateErrorState("input5", error ? true : false)
              }
              onKeyUp={(e: any) => {
                dispatch(
                  updateField({
                    field: "taxId",
                    value: e.target.value,
                  })
                );
              }}
              initialValue={participant.taxId}
            />
            <TXDInput
              id="dateOfBirth"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Birth Date"
              placeholder="Enter birth date"
              sizing="xs"
              type="date"
              variant="styled"
              regex={REGEX_CONSTANTS.DOB_REGEX}
              required
              errorExist={(error) =>
                updateErrorState("input6", error || !!dateError ? true : false)
              }
              onKeyUp={handleInputKeyUp("dob")}
              onBlur={handleInputBlur("dob")}
              initialValue={participant.dob}
              onInput={handleDateChange}
              max={new Date().toISOString().split("T")[0]}
            />
            {dateError && (
              <TXDBox className="text-red-500 font-normal text-sm leading-[1.25rem]">
                {dateError}
              </TXDBox>
            )}
            <TXDSelect
              id="martialStatus"
              className="external-downimg pl-0"
              isObjectArray
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Marital Status"
              optionsArray={MARTIAL_STATUS.map((status) => ({
                label: status.name || status.label,
                value: status.value,
                disabled: status.disabled,
              }))}
              sizing="xs"
              variant="styled"
              onChange={handleInputChange("martialStatus")}
              defaultValue={participant.martialStatus}
            />
          </TXDBox>
          {/* 3rd */}
          <TXDBox className="flex-1 hidden sm:flex">
            <TXDBox className="ml-0 sm:ml-10 flex">
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="h-4 w-4 opacity-60 pr-2 text-blue-600"
              />
              <TXDText
                weight="normal"
                size="sm"
                as="p"
                className=" text-gray-500  pb-2 sm:pb-10 text-justify text-xs sm:pr-12 pr-0"
              >
                To add a new Participant, simply enter the required information
                and click on the button Next to go to the next step.
              </TXDText>
            </TXDBox>
          </TXDBox>
        </TXDFlex>
        <TXDBox className="mt-5 w-full md:w-1/2">
          <TXDBox>
            <TXDText
              as="h2"
              className="text-gray-900  uppercase my-1 border-gray-200 border-b pb-2 text-xs sm:text-sm"
              size="sm"
              weight="semibold"
            >
              CONTACT INFORMATION
            </TXDText>
          </TXDBox>
        </TXDBox>
        <TXDBox className="mt-4 w-full md:w-1/2 ">
          <TXDFlex variant="column" className="gap-1">
            <TXDInput
              id="phoneNumber"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Phone Number"
              placeholder="Enter phone number"
              sizing="xs"
              type="tel"
              variant="styled"
              regex={REGEX_CONSTANTS.PHONE_REGEX}
              errorExist={(error: any) =>
                updateErrorState("input8", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("phoneNo")}
              initialValue={participant.phoneNo}
            />
            <TXDInput
              id="email"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
              labelText="Email Address"
              placeholder="Enter email address"
              sizing="xs"
              type="text"
              variant="styled"
              regex={REGEX_CONSTANTS.EMAIL_REGEX}
              errorExist={(error) =>
                updateErrorState("input9", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("email")}
              initialValue={participant.email}
            />
          </TXDFlex>
        </TXDBox>
        <TXDBox className="mt-5 w-full md:w-1/2">
          <TXDText
            as="h2"
            className="text-gray-900 uppercas my-1 border-gray-200 border-b uppercase pb-2 text-xs sm:text-sm"
            size="sm"
            weight="semibold"
          >
            PHYSICAL ADDRESS
          </TXDText>
        </TXDBox>
        <TXDBox className="mt-4 w-full md:w-1/2 mb-[100px]">
          <TXDFlex variant="column" className="gap-1">
            <TXDInput
              id="address"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Street Address"
              placeholder="Enter street address"
              sizing="xs"
              variant="styled"
              required
              errorExist={(error: any) =>
                updateErrorState("input10", error ? true : false)
              }
              value={participant.streetAddress}
              onInput={(e: any) => {
                dispatch(
                  updateField({
                    field: "streetAddress",
                    value: e.target.value,
                  })
                );
              }}
              // onKeyUp={handleInputKeyUp("streetAddress")}
              // initialValue={participant.streetAddress}
            />
            <TXDInput
              id="streetAddress2"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Street Address 2"
              placeholder="Enter Street Address 2 (Optional)"
              sizing="xs"
              variant="styled"
              className="mb-1 pl-0"
              errorExist={(error: any) =>
                updateErrorState("input11", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("streetAddress2")}
              initialValue={participant.streetAddress2}
            />
            <TXDText
              as="p"
              size="xs"
              weight="normal"
              className="text-gray-400 whitespace-nowrap mt-1 mb-1"
            >
              (address line 2)
            </TXDText>

            <TXDSelect
              id="stateProvince"
              className="external-downimg pl-0"
              isObjectArray
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="State or Province"
              optionsArray={STATE_PROVINCE["US"].map((state) => ({
                label: state.name || state.label,
                value: state.state_code || state.value,
                disabled: state.disabled,
              }))}
              sizing="xs"
              variant="styled"
              value={participant.stateProvinces}
              onChange={(e: any) => {
                dispatch(
                  updateField({
                    field: "stateProvinces",
                    value: e.target.value,
                  })
                );
              }}
              required
            />

            <TXDText
              as="p"
              size="xs"
              weight="normal"
              className="text-gray-400 whitespace-nowrap mt-1 mb-1"
            >
              (no abbreviations)
            </TXDText>
            <TXDInput
              id="selectCity"
              label
              labelClass="font-medium text-gray-400 cursor-pointer text-xs "
              variant="styled"
              className="pl-0"
              labelText={"City"}
              placeholder="Enter city name"
              type="text"
              sizing="xs"
              regex={REGEX_CONSTANTS.CITY_REGEX}
              minLength={2}
              maxLength={30}
              required
              onInput={(e: any) => {
                dispatch(
                  updateField({
                    field: "city",
                    value: e.target.value,
                  })
                );
              }}
              value={participant.city}
              errorExist={(error: any) =>
                updateErrorState("input13", error ? true : false)
              }
              // onKeyUp={handleInputKeyUp("city")}
            />

            <TXDInput
              id="zipCode"
              className="pl-0"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Zip or Postal Code"
              placeholder="Enter zip or postal code"
              sizing="xs"
              type="text"
              variant="styled"
              regex={REGEX_CONSTANTS.ZIP_CODE_REGEX}
              required
              onInput={(e: any) => {
                dispatch(
                  updateField({
                    field: "zipCode",
                    value: e.target.value,
                  })
                );
              }}
              value={participant.zipCode}
              errorExist={(error) =>
                updateErrorState("input14", error ? true : false)
              }
              // onKeyUp={handleInputKeyUp("zipCode")}
            />
            <TXDSelect
              className="external-downimg pl-0"
              id="occupationPermId"
              isObjectArray
              label
              defaultValue={
                participant.occupation ? participant.occupation : ""
              }
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Select Occupation"
              optionsArray={[
                {
                  label: "-- Please select occupation --",
                  value: "",
                  disabled: true,
                },
                ...Object.entries(OCCUPATION_FIELD).map(([key, value]) => ({
                  label: value.name,
                  value: value.value,
                })),
              ]}
              onChange={handleInputChange("occupation")}
              sizing="xs"
              variant="styled"
            />
          </TXDFlex>
          <TXDFlex variant={"column"} className="gap-3 my-6">
            <TXDText
              as="label"
              className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
            >
              Select At Least One Amount Mthod
              <span className="text-red-600 ml-[2px] font-bold">*</span>
            </TXDText>
            <TXDBox className="flex gap-2">
              <input
                type="checkbox"
                id="RothIRA"
                value={"RothIRA"}
                required={currentIRACheckboxState}
                className="h-4 w-4 rounded border-gray-300 text-[#00AFD8] focus:ring-0 outline-0"
                checked={currentIRACheckboxState}
                onChange={(e: any) => {
                  if (e.target.checked) {
                    setIRACheckboxState(true);
                    dispatch(
                      updateField({
                        field: "RothIRA",
                        value: true,
                      })
                    );
                  } else {
                    setIRACheckboxState(false);
                    dispatch(
                      updateField({
                        field: "RothIRA",
                        value: false,
                      })
                    );
                    dispatch(
                      updateField({
                        field: "RothIRAAmount",
                        value: "",
                      })
                    );
                  }
                }}
              />
              <label
                htmlFor="RothIRA"
                className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              >
                Roth IRA
              </label>
            </TXDBox>
            <TXDBox className="flex gap-2">
              <input
                type="checkbox"
                id="TraditionalIRA"
                value={"TraditionalIRA"}
                required={currentTraditionalIRACheckboxState}
                checked={currentTraditionalIRACheckboxState}
                className="h-4 w-4 rounded border-gray-300 text-[#00AFD8] focus:ring-0 outline-0"
                onChange={(e: any) => {
                  if (e.target.checked) {
                    setTraditionalIRACheckboxState(true);
                    dispatch(
                      updateField({
                        field: "TraditionalIRA",
                        value: true,
                      })
                    );
                  } else {
                    setTraditionalIRACheckboxState(false);
                    dispatch(
                      updateField({
                        field: "TraditionalIRA",
                        value: false,
                      })
                    );
                    dispatch(
                      updateField({
                        field: "TraditionalRothIRAAmount",
                        value: "",
                      })
                    );
                  }
                }}
              />
              <label
                htmlFor="TraditionalIRA"
                className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              >
                Traditional IRA
              </label>
            </TXDBox>
            <TXDText
              as="label"
              className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
            >
              Select RMD
            </TXDText>
            <TXDBox className="flex gap-2">
              <input
                type="checkbox"
                id="RMD"
                value={"RMD"}
                checked={participant.rmd}
                onChange={(e: any) => {
                  if (e.target.checked) {
                    dispatch(
                      updateField({
                        field: "rmd",
                        value: true,
                      })
                    );
                  } else {
                    dispatch(
                      updateField({
                        field: "rmd",
                        value: false,
                      })
                    );
                  }
                }}
                className="h-4 w-4 rounded border-gray-300 text-[#00AFD8] focus:ring-0 outline-0"
              />
              <label
                htmlFor="RMD"
                className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              >
                RMD
              </label>
            </TXDBox>
            <TXDBox hidden={currentIRACheckboxState ? false : true}>
              <TXDInput
                id="IRAAmount"
                className="pl-0"
                label
                labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                labelText="Roth IRA AMOUNT"
                placeholder="Enter Roth IRA Amount"
                sizing="xs"
                type="text"
                variant="styled"
                regex={REGEX_CONSTANTS.IRA_REGEX}
                required={currentIRACheckboxState}
                errorExist={(error) =>
                  updateErrorState("input18", error ? true : false)
                }
                onKeyUp={handleInputKeyUp("RothIRAAmount")}
                initialValue={participant.RothIRAAmount}
              />
            </TXDBox>

            <TXDBox hidden={currentTraditionalIRACheckboxState ? false : true}>
              <TXDInput
                id="TraditionalIRAAmount"
                className="pl-0"
                label
                labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                labelText="TRADITIONAL IRA AMOUNT"
                placeholder="Enter TraditionalIRA Amount"
                sizing="xs"
                type="text"
                variant="styled"
                regex={REGEX_CONSTANTS.IRA_REGEX}
                required={currentTraditionalIRACheckboxState}
                errorExist={(error) =>
                  updateErrorState("input19", error ? true : false)
                }
                onKeyUp={handleInputKeyUp("TraditionalRothIRAAmount")}
                initialValue={participant.TraditionalRothIRAAmount}
              />
            </TXDBox>
            <TXDBox>
              <TXDInput
                id="employeeCountry"
                label
                labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                placeholder="Employer Country"
                labelText="Employer Country (Optional)"
                regex={REGEX_CONSTANTS.OPTIONAL_REGEX}
                type="text"
                minLength={0}
                maxLength={30}
                sizing="xs"
                variant="styled"
                className="pl-0"
                errorExist={(error: any) =>
                  updateErrorState("input20", error ? true : false)
                }
                onKeyUp={handleInputKeyUp("EmployeeCounrty")}
                initialValue={participant.EmployeeCounrty}
              />
            </TXDBox>
            <TXDInput
              id="employeeName"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Employer Name (Optional)"
              placeholder="Employer Name"
              sizing="xs"
              regex={REGEX_CONSTANTS.OPTIONAL_REGEX}
              type="text"
              minLength={0}
              maxLength={30}
              variant="styled"
              className="pl-0"
              errorExist={(error: any) =>
                updateErrorState("input21", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("EmployeeName")}
              initialValue={participant.EmployeeName}
            />
            <TXDSelect
              className="external-downimg pl-0"
              id="martialStatus"
              isObjectArray
              label
              defaultValue={participant.Country || "USA"}
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Country"
              optionsArray={[
                {
                  disabled: true,
                  label: "--Select Country--",
                  value: "",
                },
                {
                  label: "United States of America",
                  value: "USA",
                },
              ]}
              sizing="xs"
              variant="styled"
              required
            />
            <TXDInput
              id="nameSuffix"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Name Suffix"
              placeholder="Name suffix (Optional)"
              regex={REGEX_CONSTANTS.OPTIONAL_REGEX}
              type="text"
              minLength={0}
              maxLength={30}
              sizing="xs"
              variant="styled"
              className="pl-0"
              errorExist={(error: any) =>
                updateErrorState("input23", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("nameSuffux")}
              initialValue={participant.nameSuffux}
            />
            <TXDInput
              id="nickName"
              label
              labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
              labelText="Nickname"
              placeholder="Nick name (Optional)"
              regex={REGEX_CONSTANTS.OPTIONAL_REGEX}
              type="text"
              minLength={0}
              maxLength={30}
              sizing="xs"
              variant="styled"
              className="mb-1 pl-0"
              errorExist={(error: any) =>
                updateErrorState("input24", error ? true : false)
              }
              onKeyUp={handleInputKeyUp("nickname")}
              initialValue={participant.nickname}
            />
          </TXDFlex>
        </TXDBox>

        {/* </form> */}
      </TXDBox>
    </>
  );
};

export default MainInformationModal;
