import React from "react";
import { TXDFlex } from "@savedaily/txd-components";
import { IPageWrapperProps } from "@/interfaces";

const PageWrapper: React.FC<IPageWrapperProps> = ({ children }) => {
  return (
    <TXDFlex variant="column" className="min-h-screen bg-[#FAFAFA] pb-32">
      {children}
    </TXDFlex>
  );
};

export default PageWrapper;
