export const RO_CONSTANTS = Object.freeze({
  PARTNER_PERM: Object.freeze({
    ID: Number(process.env.REACT_APP_ROLLOVER_PARTNER_PERM_ID),
  }),

  ACCESS: Object.freeze({
    KEY: process.env.REACT_APP_ROLLOVER_API_ACCESS_KEY,
  }),

  API_CATEGORY: Object.freeze({
    ACCESS_CODE: "/accesscode",
    EXTERNAL_MANAGEMENT: "/ExternalMgmt",
  }),

  API_CATEGORY_TYPES: Object.freeze({
    ACCESS_CODE_API: Object.freeze({
      GET_USER_LOGIN: "GetUserLogin",
      USER_RESET_PW_RQ: "UserResetPwRq",
      USER_RESET_PW: "UserResetPw",
      REFRESH_ACCESS_CODE: "RefreshAccessCode",
      SITE_PERMISSION: "SitePermission",
      PAGE_PERMISSION: "PagePermission",
      VALIDATE_TFA_CODE: "ValidateTFACode",
      GET_ACCESS_CODE: "GetAccessCode",
      CHECK_USER_NAME: "CheckUserName",
    }),

    EXTERNAL_MANAGEMENT_API: Object.freeze({
      INTERNAL_RQ: Object.freeze({
        PORTFOLIO_INQ: "InternalPortfolioInqRq",
        ADD_ADMINISTRATOR: "InternalAdministratorAddRq",
        ADD_ADVISOR_AGENT: "InternalAdvisorAgentAddRq",
        ADD_PARTICIPANT: "InternalParticipantAddRq",
        ADD_ADVISOR: "InternalAdvisorAddRq",
        ADD_SPONSOR: "InternalSponsorAddRq",
        UPLOAD_PARTICIPANT: "InternalParticipantUploadRq",
        UPLOAD_DOCS: "InternalUploadRequiredDocumentRq",
        TODO_LIST: "InternalPartnerToDoInqRq",
        COMPLETE_TODO: "InternalPartnerToDoCompleteRq",
        ARCHIVE_TODO: "InternalPartnerToDoArchiveRq",
      }),

      EXTERNAL_RQ: Object.freeze({
        PORTFOLIO_INQ: "PortfolioInqRq",
        ADD_ADDRESS: "PortfolioAddressAddRq",
        CONTACT_MOD: "PortfolioContactModRq",
        STATUS_MOD: "PortfolioStatusModRq",
        PORTFOLIO_ADD: "PortfolioAddRq",
        TRANSACTION_INQ: "TransactionInqRq",
        TRANSACTION_DETAIL: "TransactionDetailInqRq",
        PORTFOLIO_STATUS: "PortfolioStatusModRq",
      }),
    }),
    PORTFOLIO_MGT: Object.freeze({
      GET_DOCS: "PortfolioDocumentInq",
      DOWNLOAD_DOCS: "DownloadDocumentRq",
    }),
  }),

  PORTFOLIO_TYPE_ID: Object.freeze({
    PLAN_TPA: 19,
    PLAN_ADMINISTRATOR: 26,
    PLAN_SPONSOR: 21,
    PLAN_PARTICIPANT: 10,
  }),
});
