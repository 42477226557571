import React from "react";
import { TXDBox } from "@savedaily/txd-components";
import { IInfoTileProps } from "@/interfaces";

const InfoTile: React.FC<IInfoTileProps> = ({ children }) => {
  return (
    <TXDBox className="grid grid-cols-1 gap-10 sm:gap-10 md:gap-10 lg:gap-10 xl:gap-8 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 h-auto mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8 px-0 py-0 ">
      {children}
    </TXDBox>
  );
};

export default InfoTile;
