import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDSelect,
} from "@savedaily/txd-components";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import "../../styles/index.css";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { INTERNAL_PLAN_ADVISOR_ADD } from "@/services/api/userService";
import { IAddPlanAdministratormodalProps } from "@/interfaces";
import SaveSpinner from "../skeletons/SaveSpinner";
import { BUSINESS_TYPES, STATE_PROVINCE } from "@/constants/enumConstants";
import { loadGoogleMapsScript } from "@/utils/componentUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
import { API_BODY_CONSTANTS } from "@/constants/constant";
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from "../skeletons/toastUtils";
declare global {
  interface Window {
    google: any;
  }
}

const AddPlanAdministratorModal: React.FC<IAddPlanAdministratormodalProps> = ({
  modalOpen,
  closeModal,
  onAddAdminstrator,
  onAdministratorAdd,
}: any) => {
  // console.log("This is function of refetch => ", onAdministratorAdd);
  const [loading, setLoading] = useState(false);
  // const [addressValue, setAddressValue] = useState<any>({});
  const [currentValue, setCurrentValues] = useState<any>({
    fullAddress: "",
    city: "",
    country: "",
    postalCode: "",
    stateProvince: "",
  });
  // const [currentCity, setCurrentCity] = useState(addressValue.city);
  const portfolioPermId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const AccessCode = useSelector((state: RootState) => state.auth.AccessCode);

  useEffect(() => {
    if (modalOpen) {
      const initializeGoogle = async () => {
        try {
          await loadGoogleMapsScript("address", setCurrentValues);
        } catch (error) {
          // console.log(error);
        }
      };
      initializeGoogle();
    }
  }, [modalOpen, currentValue.fullAddress]);

  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
    input9: false,
    input10: false,
    input11: false,
    input12: false,
    input13: false,
    input14: false,
    input15: false,
    input16: false,
    input17: false,
    input18: false,
    input19: false,
    input20: false,
  });

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    const sameAsSponsor = e.target[0].checked;
    const legalName = e.target[1].value;
    const firstName = e.target[2].value;
    const middleName = e.target[3].value;
    const lastName = e.target[4].value;
    const taxId = e.target[5].value;
    const businessClass = e.target[6].value;
    const streetAddress = e.target[7].value;
    const streetAddress2 = e.target[8].value;
    const stateProvincs = e.target[9].value;
    const city = e.target[10].value;
    const zipCode = e.target[11].value;
    const phoneNo = e.target[12].value;
    const email = e.target[13].value;
    const country = e.target[14].value;
    const formattedPhone = phoneNo.replace(/\D/g, "");

    // console.log("This is API data =>", {
    //   AgentPermId: Number(portfolioPermId),
    //   LegalName: legalName || `${firstName} ${lastName}`,
    //   TaxId: taxId,
    //   BusinessClass: Number(businessClass),
    //   Addr1: streetAddress,
    //   Addr2: streetAddress2,
    //   City: city,
    //   StateProv: stateProvincs,
    //   PostalCode: zipCode,
    //   Country: country,
    //   FirstName: firstName,
    //   MiddleName: middleName,
    //   LastName: lastName,
    //   EmailAddr: email,
    //   Phone: formattedPhone,
    //   PhoneType: formattedPhone.length,
    //   Options: sameAsSponsor ? 12 : 0,
    //   ReferValue: API_BODY_CONSTANTS.REFER_VALUE,
    // });

    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.INPUTS_ERROR);
      setLoading(false);
      return;
    }
    try {
      const apiData = {
        AgentPermId: Number(portfolioPermId),
        LegalName: legalName || `${firstName} ${lastName}`,
        TaxId: taxId,
        BusinessClass: Number(businessClass),
        Addr1: streetAddress,
        Addr2: streetAddress2,
        City: city,
        StateProv: stateProvincs,
        PostalCode: zipCode,
        Country: country,
        FirstName: firstName,
        MiddleName: middleName,
        LastName: lastName,
        EmailAddr: email,
        Phone: formattedPhone,
        PhoneType: formattedPhone.length,
        Options: sameAsSponsor ? 12 : 0,
        ReferValue: API_BODY_CONSTANTS.REFER_VALUE,
      };
      const response = await INTERNAL_PLAN_ADVISOR_ADD(AccessCode, apiData);
      // console.log(response);
      if (response && response.PortfolioAddRs) {
        toast.success(SUCCESS_MESSAGES.ADDMINSTRATOR_ADDED);
        if (onAdministratorAdd) {
          onAdministratorAdd();
        }
        onAddAdminstrator(response.PortfolioAddRs);
        closeModal();
        location.reload();
      } else {
        throw new Error(ERROR_MESSAGES.INVALID_SERVER_RESPONSE);
      }
    } catch (error: any) {
      toast.error(ERROR_MESSAGES.ADMINSTRATOR_ADD_FAILED || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };
  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center "
          onClose={() => {
            //Do nothing
          }}
          data-testid="editAdminstratorModal"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <TXDBox className="inline-block  w-full max-w-2xl my-8 mx-12  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <TXDFlex
                justifyContent="center"
                className=" "
                alignItems="center"
              >
                <TXDBox className="modalOverlay w-full ">
                  <TXDBox className="">
                    <TXDFlex
                      alignItems="center"
                      className="p-4 flex justify-between border-b"
                    >
                      <TXDText
                        align="left"
                        as="h1"
                        className="uppercase  text-gray-500 sm:text-xl text-md whitespace-nowrap "
                        size="xl"
                        weight="normal"
                      >
                        Add Administrator
                      </TXDText>
                      <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                          onClick={closeModal}
                        />
                      </TXDBox>
                    </TXDFlex>
                  </TXDBox>
                  <TXDFlex
                    className="overflow-y-auto px-4 my-4 participant-table"
                    style={{ maxHeight: "calc(100vh - 160px)" }}
                  >
                    <form
                      className="w-full"
                      id="profileForm"
                      onSubmit={handleSubmit}
                      onKeyDown={handleKeyDown}
                    >
                      <TXDBox className="pb-28 sm:pb-20">
                        <TXDBox className="flex flex-col pb-2 gap-2">
                          <TXDBox>
                            <label
                              htmlFor="sameAsSponsor"
                              className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                            >
                              Same As Sponsor
                            </label>
                          </TXDBox>
                          <TXDFlex className="gap-2">
                            <input
                              type="checkbox"
                              id="sameAsSponsor"
                              value={"sameAsSponsor"}
                              className="h-4 w-4 rounded border-gray-300 text-[#00AFD8] focus:ring-0 outline-0"
                            />
                            <label
                              htmlFor="sameAsSponsor"
                              className="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                            >
                              Please check if same as sponsor
                            </label>
                          </TXDFlex>
                        </TXDBox>
                        <TXDInput
                          id="legalName"
                          className="pl-0"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Company Name"
                          placeholder="Enter company name"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          minLength={2}
                          maxLength={200}
                          regex={REGEX_CONSTANTS.ADMINISTRATIVE_FIRM_REGEX}
                          initialValue=""
                          // required
                        />
                        <TXDInput
                          id="firstName"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                          labelText="First Name"
                          placeholder="Enter first name"
                          className="pl-0"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          regex={REGEX_CONSTANTS.FIRST_NAME_REGEX}
                          minLength={1}
                          maxLength={30}
                          required
                          errorExist={(error: any) =>
                            updateErrorState("input1", error ? true : false)
                          }
                          initialValue=""
                        />
                        <TXDInput
                          id="middleName"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                          labelText="Middle Name"
                          placeholder="Enter middle name"
                          className="pl-0"
                          sizing="xs"
                          type="text"
                          minLength={1}
                          maxLength={30}
                          variant="styled"
                          regex={REGEX_CONSTANTS.MIDDLE_NAME_REGEX}
                          errorExist={(error: any) =>
                            updateErrorState("input2", error ? true : false)
                          }
                          initialValue=""
                        />
                        <TXDInput
                          id="lastName"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                          labelText="Last Name"
                          placeholder="Enter last name"
                          sizing="xs"
                          minLength={1}
                          maxLength={30}
                          type="text"
                          className="pl-0"
                          variant="styled"
                          regex={REGEX_CONSTANTS.LAST_NAME_REGEX}
                          required
                          errorExist={(error: any) =>
                            updateErrorState("input3", error ? true : false)
                          }
                          initialValue=""
                        />
                        <TXDInput
                          id="taxId"
                          label
                          className="pl-0"
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Tax ID"
                          placeholder="Enter tax ID"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          regex={REGEX_CONSTANTS.TAX_ID_REGEX}
                          required
                          errorExist={(error) =>
                            updateErrorState("input5", error ? true : false)
                          }
                          initialValue=""
                        />
                        <TXDSelect
                          className="external-downimg pl-0"
                          id="slecetBussinessClass"
                          isObjectArray
                          label
                          defaultValue={""}
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Business Class"
                          sizing="xs"
                          variant="styled"
                          required
                          optionsArray={[
                            {
                              label: "-- Please select bussiness class --",
                              value: "",
                              disabled: true,
                            },
                            ...BUSINESS_TYPES.map((business) => ({
                              label: business.label,
                              value: business.value,
                            })),
                          ]}
                        />
                        <TXDInput
                          id="address"
                          className="pl-0"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                          labelText="Street Address"
                          placeholder="Enter Street address"
                          sizing="xs"
                          variant="styled"
                          required
                          value={currentValue.fullAddress}
                          onInput={(e: any) => {
                            setCurrentValues({
                              ...currentValue,
                              fullAddress: e.target.value || "",
                            });
                            // setAddressValue(e.currentTarget.value);
                          }}
                          errorExist={(error: any) =>
                            updateErrorState("input8", error ? true : false)
                          }
                        />
                        <TXDInput
                          id="streetAddress2"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                          labelText="Street Address 2"
                          placeholder="Enter street address 2 (Optional)"
                          sizing="xs"
                          variant="styled"
                          className="mb-1 pl-0"
                          errorExist={(error: any) =>
                            updateErrorState("input9", error ? true : false)
                          }
                        />
                        <TXDText
                          as="p"
                          size="xs"
                          weight="normal"
                          className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                        >
                          (address line 2)
                        </TXDText>
                        <TXDSelect
                          className="external-downimg pl-0"
                          id="selectStateProvince"
                          isObjectArray
                          label
                          onChange={(e: any) => {
                            setCurrentValues({
                              ...currentValue,
                              stateProvince: e.target.value || "",
                            });
                          }}
                          value={currentValue.stateProvince}
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="State or Province"
                          optionsArray={STATE_PROVINCE["US"].map((state) => ({
                            label: state.name || state.label,
                            value: state.state_code || state.value,
                            disabled: state.disabled,
                          }))}
                          sizing="xs"
                          variant="styled"
                          required
                        />
                        <TXDText
                          as="p"
                          size="xs"
                          weight="normal"
                          className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                        >
                          (no abbreviations)
                        </TXDText>
                        <TXDInput
                          id="city"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer text-xs "
                          variant="styled"
                          className="pl-0"
                          labelText={"City"}
                          placeholder="Enter city name"
                          type="text"
                          sizing="xs"
                          minLength={2}
                          maxLength={30}
                          regex={REGEX_CONSTANTS.CITY_REGEX}
                          value={currentValue.city}
                          onInput={(e: any) => {
                            // console.log(e.target.value);
                            setCurrentValues({
                              ...currentValue,
                              city: e.target.value || "",
                            });
                            // setAddressValue(e.currentTarget.value);
                          }}
                          errorExist={(error: any) =>
                            updateErrorState("input11", error ? true : false)
                          }
                          required
                        />
                        <TXDInput
                          id="zipCode"
                          className="pl-0"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                          labelText="Zip or Postal Code"
                          placeholder="Enter zip or postal code"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          regex={REGEX_CONSTANTS.ZIP_CODE_REGEX}
                          required
                          value={currentValue.postalCode}
                          onInput={(e: any) => {
                            // console.log(e.target.value);
                            setCurrentValues({
                              ...currentValue,
                              postalCode: e.target.value || "",
                            });
                            // setAddressValue(e.currentTarget.value);
                          }}
                          errorExist={(error: any) =>
                            updateErrorState("input12", error ? true : false)
                          }
                        />
                        <TXDText
                          as="p"
                          size="xs"
                          weight="normal"
                          className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                        >
                          (or Postal Code)
                        </TXDText>
                        <TXDInput
                          id="phoneNumber"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                          labelText="Phone Number"
                          placeholder="Enter phone number"
                          sizing="xs"
                          type="tel"
                          className="pl-0"
                          variant="styled"
                          regex={REGEX_CONSTANTS.PHONE_REGEX}
                          required
                          errorExist={(error: any) =>
                            updateErrorState("input13", error ? true : false)
                          }
                          initialValue=""
                        />
                        <TXDInput
                          id="email"
                          className="pl-0"
                          label
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Email Address"
                          placeholder="Enter email address"
                          sizing="xs"
                          type="text"
                          variant="styled"
                          regex={REGEX_CONSTANTS.EMAIL_REGEX}
                          required
                          errorExist={(error) =>
                            updateErrorState("input14", error ? true : false)
                          }
                          initialValue=""
                        />
                        <TXDSelect
                          className="external-downimg pl-0"
                          id="country"
                          isObjectArray
                          label
                          defaultValue={"USA"}
                          labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                          labelText="Country"
                          optionsArray={[
                            {
                              disabled: true,
                              label: "--Select Country--",
                              value: "",
                            },
                            {
                              label: "United States of America",
                              value: "USA",
                            },
                          ]}
                          sizing="xs"
                          variant="styled"
                          required
                        />
                      </TXDBox>
                      <TXDFlex
                        justifyContent="end"
                        className="gap-4 px-4 py-4 bg-white fixed bottom-0 left-0 right-0 flex-col-reverse sm:flex-row z-30"
                      >
                        <TXDButton
                          className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 sm:w-[115px]"
                          radius="full"
                          // size="md"
                          variant="secondary"
                          border={false}
                          onClick={closeModal}
                        >
                          Cancel
                        </TXDButton>
                        <TXDButton
                          className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                          radius="full"
                          // size="lg"
                          variant="primary"
                          type="submit"
                        >
                          {loading ? <SaveSpinner /> : "SAVE"}
                        </TXDButton>
                      </TXDFlex>
                    </form>
                  </TXDFlex>
                </TXDBox>
              </TXDFlex>
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddPlanAdministratorModal;
