export const specificPage = (currentPageId: any, pagesArray: any) => {
  return pagesArray.find(
    (pageObject: any) => pageObject.PageId === currentPageId
  );
};

export const findComponent = (SectionId: any, componentList: any) => {
  const component: any = componentList.SectionList.find(
    (sectionits: any) => sectionits.SectionId === SectionId
  );

  return component ? true : false;
};

export const findPermissionList = (sectionId: any, data: any) => {
  const defaultPermissions: any = {
    read: false,
    write: false,
    update: false,
    delete: false,
  };

  const section: any = data.SectionList.find(
    (sectionits: any) => sectionits.SectionId === sectionId
  );

  if (section) {
    section.Permissions.forEach((permissions: any) => {
      const { PermissionName } = permissions;

      if (
        Object.prototype.hasOwnProperty.call(defaultPermissions, PermissionName)
      ) {
        defaultPermissions[PermissionName] = true;
      }
    });
    return {
      componentName: sectionId,
      permissionsList: defaultPermissions,
    };
  }
};
