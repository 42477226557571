export const PORTFOLIO_STATUS = Object.freeze({
  ACTIVE: 119,
  PRE_ACTIVE: 104,
  OFAC_PENDING: 300,
  DELETE: 124,
});

export type StatusCodeMap = {
  [key: number]: string;
};

export const STATUS_CODE_MAP: StatusCodeMap = Object.freeze({
  [PORTFOLIO_STATUS.ACTIVE]: "Active",
  [PORTFOLIO_STATUS.PRE_ACTIVE]: "Pre Active",
  [PORTFOLIO_STATUS.OFAC_PENDING]: "OFAC-Pending",
});

export const REMINDER_ENUMS = {
  TODO: {
    name: "RECEIVED",
    value: 338,
    badge: "warning",
  },
  COMPLETED: {
    name: "Completed",
    value: 339,
    badge: "success",
  },
  ARCHIVED: {
    name: "Archived",
    value: 340,
    badge: "success",
  },
};

export const TRANSACTION_ENUMS = [
  {
    name: "Received",
    value: "106",
    badge: "warning",
  },
  {
    name: "In-process",
    value: "107",
    badge: "warning",
  },
  {
    name: "Failed",
    value: "108",
    badge: "warning",
  },
  {
    name: "Complete*",
    value: "109",
    badge: "warning",
  },
  {
    name: "Void",
    value: "110",
    badge: "danger",
  },

  {
    name: "Pre-active",
    value: "118",
    badge: "warning",
  },
  {
    name: "Failed - Insufficient (R01)",
    value: "128",
    badge: "danger",
  },
  {
    name: " Failed - Uncollected (R09)",
    value: "128",
    badge: "danger",
  },
  {
    name: " Failed - Account Frozen (R16)",
    value: "130",
    badge: "danger",
  },
  {
    name: "Failed - Account Frozen (R16)",
    value: "131",
    badge: "danger",
  },
  {
    name: "Failed - No Account (R03)",
    value: "132",
    badge: "danger",
  },
  {
    name: "Failed - Invalid Account (R04)",
    value: "133",
    badge: "danger",
  },
  {
    name: "Failed - Non-Transaction Account (R20)",
    value: "134",
    badge: "danger",
  },
  {
    name: "Failed - Authorization Revoked (R07)",
    value: "135",
    badge: "danger",
  },
  {
    name: "Failed - Unauthorized (R10)",
    value: "136",
    badge: "danger",
  },
  {
    name: "Failed - Stop Payment (R08)",
    value: "137",
    badge: "danger",
  },
  {
    name: "Failed - Corporate Entry Unauthorized (R29)",
    value: "138",
    badge: "danger",
  },
  {
    name: "Failed - ODFI Permits Late Return (R31)",
    value: "139",
    badge: "danger",
  },
  {
    name: "Active",
    value: "164",
    badge: "success",
  },
  {
    name: "Pre-note",
    value: "168",
    badge: "warning",
  },
  {
    name: "In-Process - Waiting for Settlement",
    value: "169",
    badge: "warning",
  },
  {
    name: "Complete",
    value: "183",
    badge: "success",
  },
  {
    name: "Failed - Insufficient Funds",
    value: "184",
    badge: "danger",
  },
  {
    name: "Terminated",
    value: "189",
    badge: "danger",
  },
  {
    name: "Pending Approval",
    value: "216",
    css: "warning",
  },
  {
    name: "Fail In-process",
    value: "205",
    badge: "danger",
  },
  {
    name: "In-Process - Source Completed",
    value: "290",
    badge: "warning",
  },
  {
    name: "In-Process - Source Confirmed",
    value: "291",
    badge: "warning",
  },
  {
    name: "Transaction - Waiting For Source Complete",
    value: "294",
    badge: "warning",
  },
  {
    name: "Failed - ACH Return",
    value: "317",
    badge: "danger",
  },
  {
    name: "No Activity",
    value: "318",
    badge: "danger",
  },
];

export const STATE_PROVINCE = Object.freeze({
  US: [
    { disabled: true, label: "-- Select State --", value: "" },
    {
      name: "Alabama",
      state_code: "AL",
    },
    {
      name: "Alaska",
      state_code: "AK",
    },
    {
      name: "American Samoa",
      state_code: "AS",
    },
    {
      name: "Arizona",
      state_code: "AZ",
    },
    {
      name: "Arkansas",
      state_code: "AR",
    },
    {
      name: "Baker Island",
      state_code: "UM-81",
    },
    {
      name: "California",
      state_code: "CA",
    },
    {
      name: "Colorado",
      state_code: "CO",
    },
    {
      name: "Connecticut",
      state_code: "CT",
    },
    {
      name: "Delaware",
      state_code: "DE",
    },
    {
      name: "District of Columbia",
      state_code: "DC",
    },
    {
      name: "Florida",
      state_code: "FL",
    },
    {
      name: "Georgia",
      state_code: "GA",
    },
    {
      name: "Guam",
      state_code: "GU",
    },
    {
      name: "Hawaii",
      state_code: "HI",
    },
    {
      name: "Howland Island",
      state_code: "UM-84",
    },
    {
      name: "Idaho",
      state_code: "ID",
    },
    {
      name: "Illinois",
      state_code: "IL",
    },
    {
      name: "Indiana",
      state_code: "IN",
    },
    {
      name: "Iowa",
      state_code: "IA",
    },
    {
      name: "Jarvis Island",
      state_code: "UM-86",
    },
    {
      name: "Johnston Atoll",
      state_code: "UM-67",
    },
    {
      name: "Kansas",
      state_code: "KS",
    },
    {
      name: "Kentucky",
      state_code: "KY",
    },
    {
      name: "Kingman Reef",
      state_code: "UM-89",
    },
    {
      name: "Louisiana",
      state_code: "LA",
    },
    {
      name: "Maine",
      state_code: "ME",
    },
    {
      name: "Maryland",
      state_code: "MD",
    },
    {
      name: "Massachusetts",
      state_code: "MA",
    },
    {
      name: "Michigan",
      state_code: "MI",
    },
    {
      name: "Midway Atoll",
      state_code: "UM-71",
    },
    {
      name: "Minnesota",
      state_code: "MN",
    },
    {
      name: "Mississippi",
      state_code: "MS",
    },
    {
      name: "Missouri",
      state_code: "MO",
    },
    {
      name: "Montana",
      state_code: "MT",
    },
    {
      name: "Navassa Island",
      state_code: "UM-76",
    },
    {
      name: "Nebraska",
      state_code: "NE",
    },
    {
      name: "Nevada",
      state_code: "NV",
    },
    {
      name: "New Hampshire",
      state_code: "NH",
    },
    {
      name: "New Jersey",
      state_code: "NJ",
    },
    {
      name: "New Mexico",
      state_code: "NM",
    },
    {
      name: "New York",
      state_code: "NY",
    },
    {
      name: "North Carolina",
      state_code: "NC",
    },
    {
      name: "North Dakota",
      state_code: "ND",
    },
    {
      name: "Northern Mariana Islands",
      state_code: "MP",
    },
    {
      name: "Ohio",
      state_code: "OH",
    },
    {
      name: "Oklahoma",
      state_code: "OK",
    },
    {
      name: "Oregon",
      state_code: "OR",
    },
    {
      name: "Palmyra Atoll",
      state_code: "UM-95",
    },
    {
      name: "Pennsylvania",
      state_code: "PA",
    },
    {
      name: "Puerto Rico",
      state_code: "PR",
    },
    {
      name: "Rhode Island",
      state_code: "RI",
    },
    {
      name: "South Carolina",
      state_code: "SC",
    },
    {
      name: "South Dakota",
      state_code: "SD",
    },
    {
      name: "Tennessee",
      state_code: "TN",
    },
    {
      name: "Texas",
      state_code: "TX",
    },
    {
      name: "United States Minor Outlying Islands",
      state_code: "UM",
    },
    {
      name: "United States Virgin Islands",
      state_code: "VI",
    },
    {
      name: "Utah",
      state_code: "UT",
    },
    {
      name: "Vermont",
      state_code: "VT",
    },
    {
      name: "Virginia",
      state_code: "VA",
    },
    {
      name: "Wake Island",
      state_code: "UM-79",
    },
    {
      name: "Washington",
      state_code: "WA",
    },
    {
      name: "West Virginia",
      state_code: "WV",
    },
    {
      name: "Wisconsin",
      state_code: "WI",
    },
    {
      name: "Wyoming",
      state_code: "WY",
    },
  ],
});

export const MARTIAL_STATUS = Object.freeze([
  { disabled: true, label: "-- Select Marital Status -- ", value: "" },
  {
    name: "Single",
    value: "10",
  },
  {
    name: "Married",
    value: "11",
  },
  {
    name: "Unknown",
    value: "12",
  },
  {
    name: "Not Asked",
    value: "13",
  },
  {
    name: "Refused",
    value: "14",
  },
  {
    name: "Divorced",
    value: "15",
  },
  {
    name: "Domestic",
    value: "16",
  },
  {
    name: "Partner",
    value: "17",
  },
  {
    name: "Widowed",
    value: "18",
  },
  {
    name: "Separated",
    value: "19",
  },
]);

export const OCCUPATION_FIELD = Object.freeze([
  {
    name: "Office/Clerical",

    value: "10",
  },

  {
    name: "Technical",

    value: "11",
  },

  {
    name: "Manufacturing/Building",

    value: "12",
  },

  {
    name: "Professional",

    value: "13",
  },

  {
    name: "Managerial",

    value: "14",
  },

  {
    name: "Marketing",

    value: "15",
  },

  {
    name: "Self-employed",

    value: "16",
  },

  {
    name: "Retired",

    value: "17",
  },

  {
    name: "Student",

    value: "19",
  },

  {
    name: "Executive",

    value: "20",
  },

  {
    name: "Employed",

    value: "21",
  },
  {
    name: "Other",

    value: "18",
  },
]);

export const BUSINESS_TYPES = Object.freeze([
  {
    label: "C Corporation",
    value: "100",
  },

  {
    label: "Sole Proprietorship",
    value: "101",
  },

  {
    label: "Partnership",
    value: "102",
  },

  {
    label: "Limited Liability Company",
    value: "103",
  },

  {
    label: "Limited Liability Partnership",

    value: "104",
  },

  {
    label: "Union",
    value: "105",
  },

  {
    label: "Non profit",
    value: "106",
  },

  {
    label: "Government agency",
    value: "107",
  },

  {
    label: "S Corporation",
    value: "108",
  },

  {
    label: "Other",
    value: "109",
  },
]);

export const CODING_REASONS = Object.freeze([
  {
    CodingReason: 100,
    Description: "Nominal Participant Request",
  },
  {
    CodingReason: 101,
    Description: "Rollover",
  },
  {
    CodingReason: 102,
    Description: "Transfer",
  },
  {
    CodingReason: 103,
    Description: "Nominal - 59 1/2 or Older",
  },
  {
    CodingReason: 104,
    Description: "Premature No Exception - Under 59 1/2",
  },
  {
    CodingReason: 105,
    Description: "Premature With Exception",
  },
  {
    CodingReason: 106,
    Description: "Incorrect Use - No Exception",
  },
  {
    CodingReason: 107,
    Description: "Disability",
  },
  {
    CodingReason: 108,
    Description: "Death",
  },
  {
    CodingReason: 109,
    Description: "Excess Contribution",
  },
  {
    CodingReason: 110,
    Description: "Conversion",
  },
  {
    CodingReason: 111,
    Description: "Hardship Withdrawal",
  },
  {
    CodingReason: 112,
    Description: "Employment Termination",
  },
  {
    CodingReason: 113,
    Description: "Required Minimum Distribution",
  },
  {
    CodingReason: 114,
    Description: "Loan Distribution",
  },
  {
    CodingReason: 115,
    Description: "Forfeiture",
  },
  {
    CodingReason: 116,
    Description: "Employee Salary Deferral",
  },
  {
    CodingReason: 117,
    Description: "Employee Post-Tax Contribution",
  },
  {
    CodingReason: 118,
    Description: "Employee Loan Principal",
  },
  {
    CodingReason: 119,
    Description: "Employee Loan Interest",
  },
  {
    CodingReason: 120,
    Description: "Employer Match",
  },
  {
    CodingReason: 121,
    Description: "Employer Discretionary",
  },
  {
    CodingReason: 122,
    Description: "Employer Safe Harbor Nonelective Contribution",
  },
  {
    CodingReason: 123,
    Description: "Employer Safe Harbor Matching Contribution",
  },
  {
    CodingReason: 124,
    Description: "Employer Qualified Non-Elective Contribution (QNEC)",
  },
  {
    CodingReason: 125,
    Description: "Employer Qualified Matching Contribution (QMAC)",
  },
  {
    CodingReason: 126,
    Description: "Employer Money Purchase",
  },
  {
    CodingReason: 127,
    Description: "Rollover 401k",
  },
  {
    CodingReason: 128,
    Description: "Rollover 403b",
  },
  {
    CodingReason: 129,
    Description: "Rollover IRA",
  },
  {
    CodingReason: 130,
    Description: "Rollover Joint Survivor",
  },
  {
    CodingReason: 131,
    Description: "Rollover After Tax",
  },
  {
    CodingReason: 132,
    Description: "Dividend",
  },
  {
    CodingReason: 133,
    Description: "Administrator Post-Tax Other",
  },
  {
    CodingReason: 134,
    Description: "Nominal Participant Fee",
  },
  {
    CodingReason: 135,
    Description: "Determine Source: Precedence",
  },
  {
    CodingReason: 136,
    Description: "Determine Source: Pro-Rata",
  },
  {
    CodingReason: 137,
    Description: "Determine Source: Loan",
  },
  {
    CodingReason: 138,
    Description: "Employer Contribution",
  },
  {
    CodingReason: 139,
    Description: "Employer Non Discretionary",
  },
  {
    CodingReason: 140,
    Description: "Employer Prevailing Wage",
  },
  {
    CodingReason: 141,
    Description: "Employer Secondary Wage",
  },
  {
    CodingReason: 142,
    Description: "Forfeitures",
  },
  {
    CodingReason: 143,
    Description: "Employee Deductible Voluntary",
  },
  {
    CodingReason: 144,
    Description: "Employee Non Deductible Voluntary",
  },
  {
    CodingReason: 145,
    Description: "Employee Non Deductible Mandatory",
  },
  {
    CodingReason: 146,
    Description: "Non Source - Mistake In Fact",
  },
  {
    CodingReason: 147,
    Description: "Qualified Domestic Relations Orders",
  },
  {
    CodingReason: 148,
    Description: "Rollover SoloK",
  },
  {
    CodingReason: 149,
    Description: "Prevailing Wage Contribution",
  },
  {
    CodingReason: 150,
    Description: "Catchup Contribution",
  },
  {
    CodingReason: 151,
    Description: "ROTH Contribution",
  },
  {
    CodingReason: 152,
    Description: "Loan Default - In-Service",
  },
  {
    CodingReason: 153,
    Description: "Insurance Premium",
  },
  {
    CodingReason: 154,
    Description: "ROTH Conversion",
  },
  {
    CodingReason: 155,
    Description: "Death Rollover",
  },
  {
    CodingReason: 156,
    Description: "Loan Default - Death",
  },
  {
    CodingReason: 157,
    Description: "Rollover Roth IRA",
  },
]);

export const DOCUMENT_TYPE = [
  {
    name: "Document",
    value: "101",
  },
  {
    name: "Image",
    value: "102",
  },
  {
    name: "Form",
    value: "103",
  },
  {
    name: "Check",
    value: "104",
  },
  {
    name: "Spreadsheet XLS",
    value: "105",
  },
  {
    name: "Spreadsheet CSV",
    value: "106",
  },
  {
    name: "Lexis Nexus",
    value: "107",
  },
];
