import React from "react";
import PageWrapper from "@/components/PageWrapper";
import Footer from "@/components/Footer";
import IndividualParticipant from "@/components/IndividualParticipant";
import TransacationTable from "@/components/TransacationTable";
import NavHeader from "@/components/NavHeader";
import { rolePageId, SiteId } from "@/constants/constant";
import PageShimmer from "@/components/PageShimmer";
import getPagePermissionList from "@/services/getPagePermission";
import { findComponent, findPermissionList } from "@/utils/pageUtils";
import { IParticipantDashboardProps } from "@/interfaces";

const PlanParticipantDashboard: React.FC<IParticipantDashboardProps> = ({
  data,
}) => {
  const pageData = getPagePermissionList(SiteId, rolePageId.PPDashboard);
  // console.log(pageData);

  if (!pageData || pageData.SectionList.length === 0 || !data.userProfile) {
    return <PageShimmer data={data} />;
  }

  const renderComponent = (
    componentName: any,
    Component: any,
    additionalProps = {}
  ) => {
    if (findComponent(componentName, pageData)) {
      return (
        <Component
          componentPermissions={findPermissionList(componentName, pageData)}
          data={data}
          {...additionalProps}
        />
      );
    }
    return null;
  };

  return (
    <>
      <PageWrapper>
        <NavHeader data={data} />
        {renderComponent(137, IndividualParticipant)}
        {renderComponent(136, TransacationTable)}
      </PageWrapper>
      <Footer />
    </>
  );
};

export default PlanParticipantDashboard;
