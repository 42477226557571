import React, { useState } from "react";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
} from "@savedaily/txd-components";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { USER_REQUEST_RESET_PASSWORD } from "@/services/api/userService";
import SaveSpinner from "@/layouts/skeletons/SaveSpinner";
import { setReferenceValue, setUsername } from "@/redux/slices/otpSlice";
import { useDispatch } from "react-redux";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [inputErrors, setInputErrors] = useState({
    input1: false,
  });

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const EmailAddress = e.target[0].value;
    const userName = e.target[1].value;

    console.table({
      Username: userName,
      EmailAddress: EmailAddress,
    });
    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.INPUTS_ERROR);
    } else {
      toast
        .promise(USER_REQUEST_RESET_PASSWORD(userName, EmailAddress), {
          loading: LOADING_MESSAGES.PASSWORD_LOADING,
          success: <b>{SUCCESS_MESSAGES.PASSWORD_RESETED}</b>,
          error: <b>{ERROR_MESSAGES.PASSWORD_RESET_FAILED}</b>,
        })
        .then((response: any) => {
          setLoading(false);
          const refValue = response.ReferenceValue;
          dispatch(setReferenceValue(refValue));
          dispatch(setUsername(userName));
          navigate("/reset-password");
        })
        .catch((error: any) => {
          toast.error(ERROR_MESSAGES.PASSWORD_RESET_FAILED || error.message);
          setLoading(false);
        });
    }
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };

  return (
    <>
      <TXDFlex
        variant="column"
        justifyContent="center"
        className="flex min-h-full flex-1 px-6 lg:px-8 bg-[#FAFAFA]"
      >
        <TXDBox className="sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-lg shadow-lg p-10 mt-12">
          <TXDText
            as="span"
            align="center"
            size="xl"
            weight="bold"
            className="text-center flex justify-center py-4  text-gray-900"
          >
            Forgot Password
          </TXDText>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <TXDBox>
              <TXDBox className="relative mt-2">
                <TXDInput
                  id="emailAddress"
                  name="emailAddress"
                  label
                  // type="text"
                  autoComplete="text"
                  labelText="Email Address"
                  labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                  className="pl-0 placeholder:text-sm"
                  required
                  variant={"styled"}
                  placeholder="Enter Your Email"
                  regex={REGEX_CONSTANTS.EMAIL_REGEX}
                  errorExist={(error) =>
                    updateErrorState("input0", error ? true : false)
                  }
                />
                <TXDInput
                  id="userName"
                  name="userName"
                  label
                  type="text"
                  autoComplete="text"
                  labelText="Username"
                  labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                  className="pl-0 placeholder:text-sm"
                  required
                  variant={"styled"}
                  placeholder="Enter Your Username"
                  regex={REGEX_CONSTANTS.USERNAME_REGEX}
                  errorExist={(error) =>
                    updateErrorState("input1", error ? true : false)
                  }
                />
              </TXDBox>
            </TXDBox>

            <TXDBox className="text-center">
              <TXDButton
                className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] "
                radius="full"
                variant="primary"
                type="submit"
              >
                {loading ? <SaveSpinner /> : " Reset Password"}
              </TXDButton>
            </TXDBox>
          </form>
        </TXDBox>
      </TXDFlex>
    </>
  );
};

export default ForgotPassword;
