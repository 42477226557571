import React, { useState } from "react";
import { TXDBox, TXDFlex, TXDTable } from "@savedaily/txd-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation,
  faExclamationTriangle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import TableSkeleton from "@/layouts/skeletons/TableSkeleton";
import useFetchTransaction from "@/services/useFetchTransacation";
import { findTransactionStatus } from "@/utils/componentUtils";

const TransacationTable: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [currentTransactionPermId, setCurrentTransactionPermId] = useState<
    number | null
  >(null);

  const { id } = useParams<{ id: any }>();
  const defaultId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const effectiveId = id || defaultId;

  const { userTransaction, loading } = useFetchTransaction(effectiveId);

  const tableData =
    userTransaction?.TransactionData?.map((item: any) => ({
      id: item.TransactionPermId,
      transaction: <div data-sort={item.Desc}>{item.Desc}</div>,
      tradeDate: (
        <p
          className={`pl-10 w-full`}
          data-sort={new Date(item.TradeDate).toLocaleDateString()}
        >
          {new Date(item.TradeDate).toLocaleDateString()}
        </p>
      ),
      amount: (
        <p
          className={`pl-10 w-full text-${
            item.Amount >= 0 ? "green" : "red"
          }-300`}
          data-sort={item.Amount}
        >
          ${item.Amount.toLocaleString()}
        </p>
      ),
      status: (
        <span
          data-sort={findTransactionStatus(item.Status).name}
          style={{
            color:
              findTransactionStatus(item.Status).badge === "success"
                ? "rgb(74 222 128)"
                : findTransactionStatus(item.Status).badge === "warning"
                ? "rgb(250 204 21)"
                : "color: rgb(248 113 113)",
          }}
        >
          <FontAwesomeIcon
            icon={
              findTransactionStatus(item.Status).badge === "success"
                ? faCheckCircle
                : findTransactionStatus(item.Status).badge === "warning"
                ? faExclamationTriangle
                : faCircleExclamation
            }
            className="mr-2"
            style={{
              color:
                findTransactionStatus(item.Status).badge === "success"
                  ? "rgb(74 222 128)"
                  : findTransactionStatus(item.Status).badge === "warning"
                  ? "rgb(250 204 21)"
                  : "color: rgb(248 113 113)",
            }}
          />
          {findTransactionStatus(item.Status).name}
        </span>
      ),
    })) || [];
  const tableHead = ["id", "transaction", "trade date", "amount", "status"];

  return (
    <TXDBox className="shadow-lg bg-[#ffffff] mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8">
      <TXDBox className="overflow-x-auto">
        {loading ? (
          <table className="min-w-full divide-y divide-gray-300">
            <TableSkeleton />
          </table>
        ) : (
          <>
            <TXDTable
              tHead={tableHead}
              tRow={tableData}
              entries={true}
              pagination={true}
              filteration={true}
              title="LATEST TRANSACTIONS"
            />
            <TXDFlex
              justifyContent="start"
              className=" py-4 text-gray-500 px-4 gap-1 bg-white"
            >
              <p>
                <FontAwesomeIcon className="text-yellow-400" icon={faWarning} />
              </p>
              <p>Pre-Active = an estimated amount*</p>
            </TXDFlex>
          </>
        )}
      </TXDBox>
    </TXDBox>
  );
};

export default TransacationTable;
