import React, { useEffect, useState } from "react";
import { TXDBox, TXDButton, TXDFlex, TXDText } from "@savedaily/txd-components";
import CardSkeleton from "@/layouts/skeletons/CardSkeleton";
import {
  fieldMapping,
  formatPhoneNumber,
  getFullName,
} from "@/utils/componentUtils";
import { Administrator } from "@/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import EditAdvisorModal from "@/layouts/modals/EditAdvisorModal";
import { IAdministratorCardProps } from "@/interfaces";
import { useParams } from "react-router";
import useFetchUserList from "@/services/useFetchUserList";
import { RO_CONSTANTS } from "@/constants/RolloverConstants";

const AdvisorCard: React.FC<IAdministratorCardProps> = ({
  componentPermissions,
}) => {
  const { permissionsList } = componentPermissions;
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [editModal, setEditModal] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { users, loading, refetch } = useFetchUserList(
    RO_CONSTANTS.PORTFOLIO_TYPE_ID.PLAN_ADMINISTRATOR,
    id
  );

  const [localAdministrator, setlocalAdministrator] = useState<Administrator[]>(
    []
  );
  useEffect(() => {
    setlocalAdministrator(users || []);
  }, [users]);
  useEffect(() => {
    //
  }, [localAdministrator]);

  const advisorsArray = Array.isArray(users) ? users : [users];
  const handleAddSponsor = (newSponsor: any) => {
    setlocalAdministrator((prevSponsors) => [...prevSponsors, newSponsor]);
  };
  const handleEditClick = (portfolioPermId: number) => {
    setCurrentPortfolioPermId(portfolioPermId);
    setEditModal(true);
  };
  const onUpdate = () => {
    refetch()?.then(() => {
      setEditModal(false);
    });
  };

  return (
    <>
      {permissionsList.read ? (
        <TXDBox className="shadow-lg bg-white">
          {loading ? (
            <CardSkeleton />
          ) : (
            <>
              <TXDBox className="grid grid-cols-2 border-b-2 border-b-[#f9f9f9]">
                <TXDFlex
                  justifyContent="start"
                  alignItems="center"
                  className="border-l-4 py-4 px-4 border-l-[#4EC6E5]"
                >
                  <TXDText
                    align="left"
                    as="h1"
                    className="uppercase text-gray-500 whitespace-nowrap"
                    size="xl"
                    weight="normal"
                  >
                    Administrator
                  </TXDText>
                </TXDFlex>
                {permissionsList.update ? (
                  <TXDFlex
                    justifyContent="end"
                    className="p-0"
                    alignItems="center"
                  >
                    <TXDButton
                      aria-label="edit"
                      className="bg-transparent px-4 pt-3.5 hover:bg-transparent"
                      onClick={() => handleEditClick(users[0].PortfolioPermId)}
                    >
                      <TXDBox className="tooltip-wrapper" data-tooltip="Modify">
                        <FontAwesomeIcon
                          icon={faPen}
                          className="hover:text-[#4EC6E5] hover:border-[#4EC6E5] border text-sm cursor-pointer text-gray-400 border-gray-400 p-1 rounded-full"
                        />
                      </TXDBox>
                    </TXDButton>
                  </TXDFlex>
                ) : (
                  <></>
                )}
              </TXDBox>
              {editModal && currentPortfolioPermId !== null && (
                <EditAdvisorModal
                  modalOpen={editModal}
                  closeModal={() => setEditModal(false)}
                  onAddSponsor={handleAddSponsor}
                  userProfile={{ ...users }}
                  onUpdate={onUpdate}
                  portfolioPermId={currentPortfolioPermId}
                />
              )}
              {advisorsArray.length === 0 ? (
                <TXDBox className="flex justify-center items-center h-full">
                  <TXDText as="h2" size="xl" className="text-gray-500">
                    No Administrator Found
                  </TXDText>
                </TXDBox>
              ) : (
                <TXDBox className="grid py-6 border border-[#f9f9f9] grid-cols-1 overflow-hidden bg-[#ffffff] sm:grid-cols-1">
                  {advisorsArray.map((advisors: any, index: number) => {
                    const fullName = getFullName(
                      advisors.PortfolioFirstName,
                      advisors.PortfolioLastName
                    );

                    const processedFields: { [key: string]: any } = {
                      PortfolioLegalName: advisors.PortfolioLegalName,
                      PortfolioPermId: advisors.PortfolioPermId,
                      PortfolioTaxIdentifier: advisors.PortfolioTaxIdentifier,
                      PortfolioAddress1: advisors.PortfolioAddress1,
                      PortfolioFirstName: fullName,
                      PortfolioPhone: formatPhoneNumber(
                        advisors.PortfolioPhone
                      ),
                      PortfolioEmailAddress1: advisors.PortfolioEmailAddress1,
                    };

                    return (
                      <TXDBox
                        key={index}
                        className="grid px-12 grid-cols-1 overflow-hidden bg-[#ffffff] sm:grid-cols-1"
                      >
                        {Object.entries(fieldMapping).map(
                          ([key, label], index) => {
                            return (
                              <TXDBox
                                key={`${key}-${index}`}
                                className="grid grid-cols-2 gap-1"
                              >
                                <TXDBox className="p-2">
                                  <TXDText
                                    as="h4"
                                    className="text-gray-900 uppercase"
                                    size="xs"
                                    weight="medium"
                                  >
                                    {label}
                                  </TXDText>
                                </TXDBox>
                                <TXDBox className="p-2">
                                  <TXDText
                                    as="h4"
                                    className={`text-gray-900`}
                                    size="xs"
                                    weight="normal"
                                  >
                                    {processedFields[key]}
                                  </TXDText>
                                </TXDBox>
                              </TXDBox>
                            );
                          }
                        )}
                      </TXDBox>
                    );
                  })}
                </TXDBox>
              )}
            </>
          )}
        </TXDBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdvisorCard;
