import React, { useState, useEffect } from "react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDTable,
} from "@savedaily/txd-components";
import AddNewParticipantModal from "../layouts/modals/AddNewParticipantModal";
import { NavLink, useParams } from "react-router-dom";
import UploadFileModal from "../layouts/modals/UploadFileModal";
import TableSkeleton from "@/layouts/skeletons/TableSkeleton";
import { setTotalParticipants } from "../redux/slices/participantsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircle,
  faEdit,
  faEye,
  faPlus,
  faTimesCircle,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import ShowCompanyInfoModal from "@/layouts/modals/ShowCompanyInfoModal";
import EditParticipantProfileModal from "@/layouts/modals/EditParticipantProfileModal";
import { IParticipantTable } from "@/interfaces";
import {
  formatSocialSecurity,
  getHumanReadableStatus,
} from "@/utils/componentUtils";
import { RO_CONSTANTS } from "@/constants/RolloverConstants";
import useFetchUserList from "@/services/useFetchUserList";
import { resetForm } from "@/redux/slices/participantFormSlice";
import { PORTFOLIO_STATUS } from "@/constants/enumConstants";
import { PORTFOLIO_STATUS_MOD_RQ } from "@/services/api/userService";
import toast from "react-hot-toast";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";

const ParticipantsTable: React.FC<IParticipantTable> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { permissionsList } = props.componentPermissions;
  const [selectedAdminId, setSelectedAdminId] = useState<number | null>(null);
  const AccessCode = useSelector((state: RootState) => state.auth.AccessCode);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [showCompany, setShowCompany] = useState(false);
  const [toastLoading, setToastLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  if (!id) {
    <div>No Id Found</div>;
  }
  const defaultId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const effectiveId = id || defaultId;
  const { users, loading, refetch } = useFetchUserList(
    RO_CONSTANTS.PORTFOLIO_TYPE_ID.PLAN_PARTICIPANT,
    effectiveId
  );
  const [open, setOpen] = useState(false);

  const openAddParticipantModalBox = () => {
    dispatch(resetForm());
    setOpen(true);
  };

  const [upload, setUpload] = useState(false);

  useEffect(() => {
    if (users) {
      dispatch(setTotalParticipants(users.length));
    }
  }, [users, dispatch]);

  const handleEditClick = (portfolioPermId: number) => {
    const selectedParticipant = users.find(
      (user) => user.PortfolioPermId === portfolioPermId
    );
    setCurrentPortfolioPermId(portfolioPermId);
    setEditModal(true);
    setSelectedAdminId(selectedParticipant);
    setCurrentPortfolioPermId(portfolioPermId);
    setEditModal(true);
  };

  const onUpdate = () => {
    refetch().then(() => {
      // console.log("Data refetched after update");
      setEditModal(false);
    });
  };

  const formatParticipantsData = (data: any[]) => {
    return data.map((participant) => ({
      id: participant.PortfolioPermId || "N/A",
      participants: (
        <NavLink
          to={`/participant/${participant.PortfolioPermId}`}
          className="font-bold"
          data-sort={
            participant.PortfolioFirstName + " " + participant.PortfolioLastName
          }
        >
          {underlineFirstFourLetters(participant.PortfolioFirstName)}{" "}
          {participant.PortfolioLastName || "N/A"}
        </NavLink>
      ),
      socialSecurity:
        formatSocialSecurity(participant.PortfolioTaxIdentifier) || "N/A",
      assets: (
        <p className="pl-8" data-sort={participant.PortfolioAssets}>
          <p>
            {participant.PortfolioAssets
              ? `$ ${participant.PortfolioAssets.toLocaleString()}`
              : "$ 0"}
          </p>
        </p>
      ),
      status: (
        <span
          className="flex gap-1"
          data-sort={getHumanReadableStatus(participant.PortfolioStatus)}
        >
          <span>
            <FontAwesomeIcon
              className={`text-sm ${
                getHumanReadableStatus(participant.PortfolioStatus) == "Active"
                  ? " text-[#38D6B5]"
                  : `${
                      getHumanReadableStatus(participant.PortfolioStatus) ==
                      "Pre Active"
                        ? "text-yellow-400"
                        : `${
                            getHumanReadableStatus(
                              participant.PortfolioStatus
                            ) == "OFAC-Pending"
                              ? "text-blue-500"
                              : "text-red-400"
                          }`
                    }`
              }`}
              icon={faCircle}
            />
          </span>
          <span>{getHumanReadableStatus(participant.PortfolioStatus)}</span>
        </span>
      ),
      actions: (
        <TXDFlex className="gap-2" alignItems="center">
          {permissionsList.read ? (
            <TXDBox className="tooltip-wrapper" data-tooltip="View">
              <FontAwesomeIcon
                icon={faEye}
                className="cursor-pointer text-[#00AFD8]"
                onClick={() => {
                  setSelectedAdminId(participant.PortfolioPermId);
                  setShowCompany(true);
                }}
              />
            </TXDBox>
          ) : (
            <></>
          )}
          {permissionsList.update ? (
            getHumanReadableStatus(participant.PortfolioStatus) == "Active" ? (
              <></>
            ) : (
              <TXDBox className="tooltip-wrapper" data-tooltip="Modify">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="cursor-pointer text-[#38D6B5]"
                  onClick={() => handleEditClick(participant.PortfolioPermId)}
                />
              </TXDBox>
            )
          ) : (
            <></>
          )}
          {permissionsList.delete ? (
            getHumanReadableStatus(participant.PortfolioStatus) ==
            "Pre Active" ? (
              <TXDBox className="tooltip-wrapper" data-tooltip="Delete">
                <FontAwesomeIcon
                  icon={faTrash}
                  className="cursor-pointer text-[#d63838]"
                  onClick={() => deleteParticipant(participant.PortfolioPermId)}
                />
              </TXDBox>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </TXDFlex>
      ),
    }));
  };

  const onParticipantAdded = () => {
    refetch(); // Refetch the participant data
  };

  const underlineFirstFourLetters = (name: string) => {
    if (!name) return "Unknown";
    const firstFour = name.slice(0, 4);
    const rest = name.slice(4);
    return (
      <span>
        <span className="border-b-2 border-[#4EC6E5]">{firstFour}</span>
        {rest}
      </span>
    );
  };

  const tableHead = [
    "id",
    "participants",
    "Social Security",
    `Assets`,
    "Status",
    "Actions",
  ];
  const tableData = formatParticipantsData(users);

  const deleteUser = (apiData: any, AccessCode: any) => {
    toast
      .promise(Promise.all([PORTFOLIO_STATUS_MOD_RQ(apiData, AccessCode)]), {
        loading: LOADING_MESSAGES.DELETE_LOADING,
        success: <b>{SUCCESS_MESSAGES.PRODILE_DELETE}</b>,
        error: <b>{ERROR_MESSAGES.PROFILE_FAILED}</b>,
      })
      .then((response) => {
        // console.log("This is status modification functionality => ", response);
        setToastLoading(false);
        refetch();
        location.reload();
      })
      .catch((error: any) => {
        // console.log("This is error => ", error);
        toast.error(ERROR_MESSAGES.PROFILE_FAILED || error?.message);
        setToastLoading(false);
      });
  };

  const deleteParticipant = async (portfolioPermId: any) => {
    setToastLoading(true);
    const apiData = {
      Status: PORTFOLIO_STATUS.DELETE,
      PortfolioPermId: portfolioPermId,
    };

    toast((t) => (
      <TXDFlex variant={"row"} className="gap-2">
        <TXDBox className="text-md font-medium">
          Are you sure you want to delete this participant?
        </TXDBox>
        <TXDFlex variant={"row"} className="gap-2">
          <button>
            <FontAwesomeIcon
              className="text-green-600  text-xl"
              icon={faCheckCircle}
              onClick={() => deleteUser(apiData, AccessCode)}
            />
          </button>
          <button>
            <FontAwesomeIcon
              className="text-red-500 text-xl"
              icon={faTimesCircle}
              onClick={() => toast.dismiss(t.id)}
            />
          </button>
        </TXDFlex>
      </TXDFlex>
    ));
  };

  return (
    <TXDBox className="shadow-lg bg-[#ffffff] mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8">
      <TXDBox className="overflow-x-auto">
        {loading ? (
          <table className="min-w-full divide-y divide-gray-300">
            <TableSkeleton />
          </table>
        ) : (
          <TXDTable
            filteration={true}
            pagination={true}
            entries={true}
            tHead={tableHead}
            tRow={tableData}
            title="Participants"
          />
        )}
        {permissionsList.write ? (
          <TXDFlex justifyContent="end" className=" py-4  px-4 gap-4 bg-white">
            {/* <TXDBox className="tooltip-wrapper" data-tooltip="Add"> */}
            <TXDButton
              className="bg-white !text-[#4EC6E5] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5] text-medium font-semibold"
              radius="none"
              variant="primary"
              onClick={openAddParticipantModalBox}
            >
              <FontAwesomeIcon className="text-xs" icon={faPlus} /> Add
              Participant
            </TXDButton>
            {/* </TXDBox> */}
            {/* <TXDBox className="tooltip-wrapper" data-tooltip="Upload"> */}
            <TXDButton
              className="bg-white !text-[#4EC6E5] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5] text-medium font-semibold"
              radius="none"
              variant="primary"
              onClick={() => setUpload(true)}
            >
              <FontAwesomeIcon className="text-xs" icon={faUpload} /> Upload
              Participant
            </TXDButton>
            {/* </TXDBox> */}
          </TXDFlex>
        ) : (
          <></>
        )}

        {open && (
          <AddNewParticipantModal
            modalOpen={open}
            closeModal={() => setOpen(false)}
            sponsorId={id}
            onParticipantAdded={onParticipantAdded}
          />
        )}
        {upload && (
          <UploadFileModal
            modalOpen={upload}
            closeModal={() => setUpload(false)}
          />
        )}
        {showCompany && selectedAdminId !== null && (
          <ShowCompanyInfoModal
            modalOpen={showCompany}
            closeModal={() => setShowCompany(false)}
            identifier={selectedAdminId}
          />
        )}
        {editModal && currentPortfolioPermId !== null && (
          <EditParticipantProfileModal
            modalOpen={editModal}
            closeModal={() => setEditModal(false)}
            userProfile={selectedAdminId}
            onUpdate={onUpdate}
            portfolioPermId={currentPortfolioPermId}
          />
        )}
      </TXDBox>
    </TXDBox>
  );
};

export default ParticipantsTable;
