import PageWrapper from "@/components/PageWrapper";
import TableWrapper from "@/components/TableWrapper";
import { TXDBox, TXDFlex, TXDTable } from "@savedaily/txd-components";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import NavHeader from "@/components/NavHeader";
import { IParticipantsDashboardProps } from "@/interfaces";
import useFetchDocs from "@/services/useFetchDocs";
import TableSkeleton from "../skeletons/TableSkeleton";
import useDownloadDocs from "@/services/useDownloadDocs";
import DownloadSpinner from "../skeletons/DownloadSpinner";
import UploadRequiredDocs from "../modals/UploadRequiredDocsModal";
import { findDocumentTypeByCode } from "@/utils/componentUtils";
const UploadedDocuments: React.FC<IParticipantsDashboardProps> = ({
  data,
}: any) => {
  const [requiredDocs, setRequiredDocs] = useState([]);
  const [nonRequiredDocs, setNonRequiredDocs] = useState([]);
  const { fetchDocs, docsLoading } = useFetchDocs();
  const { downloadDocument, loading } = useDownloadDocs();
  const [upload, setUpload] = useState(false);
  const [docPermId, setDocPermId] = useState("");

  useEffect(() => {
    const loadDocs = async () => {
      const required = await fetchDocs(true);
      setRequiredDocs(required);
    };
    loadDocs();
  }, [fetchDocs]);

  useEffect(() => {
    const loadDocs = async () => {
      const required = await fetchDocs(true);
      const nonRequired = await fetchDocs(false);
      setRequiredDocs(required);
      setNonRequiredDocs(nonRequired);
    };
    loadDocs();
  }, [fetchDocs]);

  const formatDocuments = (docs: any, isRequired: boolean) =>
    docs.map((doc: any) => ({
      date: new Date(doc.AddDate).toLocaleDateString(),
      desc: doc.DocumentDesc,
      type: `${findDocumentTypeByCode(doc.TypePermId)}`,
      action: (
        <TXDFlex>
          {loading[doc.DocumentPermId] ? (
            <DownloadSpinner />
          ) : (
            <TXDFlex className="cursor-pointer text-[#00AFD8] px-4 pr-3">
              <TXDBox className="tooltip-wrapper" data-tooltip="Download">
                <FontAwesomeIcon
                  onClick={() => downloadDocument(doc.DocumentPermId)}
                  icon={faArrowUpFromBracket}
                  className="rotate-180"
                />
              </TXDBox>
            </TXDFlex>
          )}
        </TXDFlex>
      ),
    }));
  const formatRequiredDocuments = (docs: any, isRequired: boolean) =>
    docs.map((doc: any) => ({
      date: new Date(doc.AddDate).toLocaleDateString(),
      desc: doc.DocumentDesc,
      type: `${findDocumentTypeByCode(doc.TypePermId)}`,
      status: isRequired ? doc.ApproveStatus : "N/A",
      action: (
        <TXDFlex>
          {loading[doc.DocumentPermId] ? (
            <DownloadSpinner />
          ) : (
            <TXDFlex className="cursor-pointer text-[#00AFD8] px-4 pr-3">
              <TXDBox className="tooltip-wrapper" data-tooltip="Download">
                <FontAwesomeIcon
                  onClick={() => downloadDocument(doc.DocumentPermId)}
                  icon={faArrowUpFromBracket}
                  className="rotate-180"
                />
              </TXDBox>
            </TXDFlex>
          )}

          {doc.ApproveStatus == "Approved" ? (
            <></>
          ) : (
            <TXDFlex className="cursor-pointer text-[#00AFD8] px-4 pr-3">
              <TXDBox className="tooltip-wrapper" data-tooltip="Upload">
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  onClick={() => {
                    setUpload(true);
                    setDocPermId(doc.DocumentPermId);
                  }}
                />
              </TXDBox>
            </TXDFlex>
          )}
        </TXDFlex>
      ),
    }));

  // console.log("This is doc => ", requiredDocs);
  return (
    <PageWrapper>
      <NavHeader data={data} />
      <TableWrapper>
        {docsLoading ? (
          <table className="min-w-full divide-y divide-gray-300">
            <TableSkeleton />
          </table>
        ) : (
          <TXDTable
            pagination={true}
            tHead={["Date", "Description", "Type", "Status", "Action"]}
            filteration={true}
            tRow={formatRequiredDocuments(requiredDocs, true)}
            title="Required Documents"
          />
        )}
      </TableWrapper>
      <TableWrapper>
        {docsLoading ? (
          <table className="min-w-full divide-y divide-gray-300">
            <TableSkeleton />
          </table>
        ) : (
          <TXDTable
            pagination={true}
            tHead={["Date", "Description", "Type", "Action"]}
            tRow={formatDocuments(nonRequiredDocs, false)}
            filteration={true}
            title="Documents"
          />
        )}
        {upload && (
          <UploadRequiredDocs
            modalOpen={upload}
            closeModal={() => setUpload(false)}
            docPermId={docPermId}
          />
        )}
      </TableWrapper>
    </PageWrapper>
  );
};

export default UploadedDocuments;
