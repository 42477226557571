export const REGEX_CONSTANTS = Object.freeze({
  EMAIL_REGEX: /^[A-Za-z0-9._]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  FIRST_NAME_REGEX: /^[\s\S]+$/,
  MIDDLE_NAME_REGEX: /^[\s\S]+$/,
  LAST_NAME_REGEX: /^[\s\S]+$/,
  USERNAME_REGEX: /^[A-Za-z0-9]+$/,
  PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[\s\S]{8,30}$/,
  ADMINISTRATIVE_FIRM_REGEX: /^[A-Za-z0-9\- ]+$/,
  TAX_ID_REGEX: /^\d{9}$/,
  TITLE_REGEX: /^[A-Za-z0-9@._]+$/,
  STREET_ADDRESS_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s])[\s\S]$/,
  CITY_REGEX:
    /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+(?:[\s'-][a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)*$/,
  PHONE_REGEX: /^\(\d{3}\) \d{3}-\d{4}$/,
  ZIP_CODE_REGEX: /^\d{5}(-\d{4})?$/,
  DOB_REGEX: /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
  IRA_REGEX: /^\d+(\.\d{1,2})?$/,
  PORTFOLIO_PERM_ID_REGEX: /^\d*$/,
  OPTIONAL_REGEX: /^[A-Za-z0-9\- ]+$/,
});
