import React from "react";
import BackDashboard from "@/components/BackDashboard";
import NavHeader from "@/components/NavHeader";
import PageWrapper from "@/components/PageWrapper";
import Footer from "@/components/Footer";
import { IReminderDashboardProps } from "@/interfaces";
import PageShimmer from "@/components/PageShimmer";
import TableWrapper from "@/components/TableWrapper";
import ReminderCard from "@/components/ReminderCard";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { getPageId } from "@/utils/componentUtils";
import getPagePermissionList from "@/services/getPagePermission";
import { SiteId } from "@/constants/constant";
import { findComponent, findPermissionList } from "@/utils/pageUtils";
import { TXDTable } from "@savedaily/txd-components";

const ReminderDashboard: React.FC<IReminderDashboardProps> = ({ data }) => {
  const userPolicy = useSelector((state: RootState) => state.auth.policy);
  const pageId = getPageId(userPolicy?.PolicyId);
  const pageData = getPagePermissionList(SiteId, pageId);
  const isLoading = !data?.userProfile || !data || !pageData;

  if (isLoading) {
    return <PageShimmer data={data} />;
  }
  const hasReminderList = findComponent(106, pageData);
  const componentPermissions = hasReminderList
    ? findPermissionList(106, pageData)
    : null;

  return (
    <PageWrapper>
      <NavHeader data={data} />
      <BackDashboard />
      <TableWrapper>
        {hasReminderList ? (
          <ReminderCard componentPermissions={componentPermissions} />
        ) : (
          <TXDTable
            filteration={true}
            pagination={true}
            entries={true}
            tHead={["Task Name", "Date"]}
            tRow={[]}
            title="Reminders"
          />
        )}
      </TableWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default ReminderDashboard;
