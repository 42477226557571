import React from "react";
import { TXDBox, TXDText } from "@savedaily/txd-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const BackDashboard: React.FC = () => {
  return (
    <>
      <TXDBox className="mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8">
        <TXDText as="p" weight="medium" size="sm" className=" text-gray-600">
          <Link className="cursor-pointer" to={"/"}>
            <FontAwesomeIcon icon={faArrowLeftLong} /> Back to Dashboard
          </Link>
        </TXDText>
      </TXDBox>
    </>
  );
};

export default BackDashboard;
