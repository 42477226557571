import React from "react";
import { useSelector } from "react-redux";
import BackDashboard from "@/components/BackDashboard";
import NavHeader from "@/components/NavHeader";
import PageWrapper from "@/components/PageWrapper";
import Footer from "@/components/Footer";
import PageShimmer from "@/components/PageShimmer";
import SponsorsTable from "@/components/SponsorsTable";
import TableWrapper from "@/components/TableWrapper";
import { TXDTable } from "@savedaily/txd-components";
import { ISponsorDashboardProps } from "@/interfaces";
import getPagePermissionList from "@/services/getPagePermission";
import { SiteId } from "@/constants/constant";
import { findComponent, findPermissionList } from "@/utils/pageUtils";
import { RootState } from "@/redux/store";
import { getPageId } from "@/utils/componentUtils";

const SponsorDashboard: React.FC<ISponsorDashboardProps> = ({ data }) => {
  const userPolicy = useSelector((state: RootState) => state.auth.policy);
  const pageId = getPageId(userPolicy?.PolicyId);
  const pageData = getPagePermissionList(SiteId, pageId);
  const isLoading = !data?.userProfile || !data || !pageData;

  if (isLoading) {
    return <PageShimmer data={data} />;
  }

  const hasSponsorsList = findComponent(109, pageData);
  const componentPermissions = hasSponsorsList
    ? findPermissionList(109, pageData)
    : null;

  return (
    <PageWrapper>
      <NavHeader data={data} />
      <BackDashboard />
      {hasSponsorsList ? (
        <SponsorsTable componentPermissions={componentPermissions} />
      ) : (
        <TableWrapper>
          <TXDTable
            filteration={true}
            pagination={true}
            entries={true}
            tHead={["id", "Name", "Assets", "Status", "Actions"]}
            tRow={[]}
            title="Sponsors"
          />
        </TableWrapper>
      )}
      <Footer />
    </PageWrapper>
  );
};

export default SponsorDashboard;
