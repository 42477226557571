import toast from "react-hot-toast";
import { IToastMessages } from "@/interfaces";

/**
 * Handle promise with toasts.
 * @param promise The promise to handle.
 * @param messages Object containing messages for loading, success, and error states.
 */
export const handleToastPromise = (
  promise: Promise<any>,
  messages: IToastMessages
) => {
  return toast.promise(
    promise,
    {
      loading: messages.loading,
      success: messages.success as any, // Using type assertion to satisfy TypeScript
      error: messages.error as any, // Using type assertion to satisfy TypeScript
    },
    {
      style: { border: "1px solid #333", padding: "16px" },
      success: {
        duration: 5000,
        icon: "✅",
      },
      error: {
        duration: 5000,
        icon: "❌",
        style: { border: "1px solid red", color: "red" },
      },
    }
  );
};

export const ERROR_MESSAGES = Object.freeze({
  ERROR_SUBMIT: "Please correct the errror before submitting",
  USERNAME_TAKEN: "Username already exists. Please choose a different one.",
  USERNAME_TAKEN_FAILED: "Error checking username availability",
  ACCESS_CODE_FAILURE: "Failed to register, access code failure.",
  ACCESS_CODE_REC_FAILURE: "No access token received",
  ACCOUNT_CREATE_FAILED: "Failed to create account.",
  LOGIN_FAILED: "Login failed.",
  LOGIN_CRED_FAILED: "Failed to login or Invalid Credentials",
  INVALID_SERVER_RESPONSE: "Invalid response from server.",
  //Upload File

  INVALID_FILE_DATA:
    "Invalid data in file some of the fields may be missed or undefined",
  FILE_UPLOAD_FAILED: "Failed to upload the file.",
  GENERIC_ERROR: "An unexpected error occurred.",
  ERROR_BEFORE_SUBMIT: "Please correct the error before submitting.",
  //Permission
  PERMISSSION_FAILED: "Failed to fetch permissions",
  //Documents
  DOCS_DOWN_FAILED: "Failed to download document",
  //Trans
  FETCH_TRANS_FAILED: "Error fetching transactions",
  //Fetch Profile
  FETCH_PROFILE_FAILED: "Failed to get user profile data",
  //Upload Docs
  DOCS_UPLOAD_FAILED: "Failed to upload document",
  //Session Exp
  SESSION_EXPIRE: "Your session has expired, please log in again.",
  //Inputs Error
  INPUTS_ERROR: "Error found in inputs. Please correct them before submitting.",
  //Password Rest
  PASSWORD_RESET_FAILED: "Failed to request password reset. Please try again.",
  //OTP
  OTP_VERFIED_FAILED: "Failed to verify OTP. Please try again.",
  //task
  COMP_TASK_FAILED: "Failed to complete task",
  ARC_TASK_FAILED: "Failed to complete task",
  //login
  LOG_OUT_FAILED: "Logout failed",
  //Add Adminstrator
  ADMINSTRATOR_ADD_FAILED: "Failed to add administrator. Please try again.",
  SPONSOR_ADD_FAILED: "Failed to add sponsor. Please try again.",
  PARTICIPANT_ADD_FAILED: "Error while adding participant.Please try again.",

  PROFILE_FAILED: "Failed to update profile.",
  FILE_SELECTED_FAILED: "No file selected or unsupported file type",
  UNSUPPORTED_FILE_FAILED:
    "Unsupported file type. Please select a CSV, XLS, or XLSX file.",
  UNSUPPORTED_FILE_FAILED2:
    "Unsupported file type. Please select a PDF, Docs or Images Only.",
  GOOGLE_API_FAILED: "Google Maps API key is missing.",
  GOOGLE_API_ERROR: "Google Maps API not available",
  SPONSOR_FAILED: "Sponsor not found",
  DOCS_NOT_FOUND: "No data or empty response received.",
});

export const SUCCESS_MESSAGES = Object.freeze({
  ACCOUNT_CREATED: "Account created successfully.",
  // Documents
  DOCS_DOWNLOADED: "Document downloaded successfully.",
  //Upload Docs
  DOCS_UPLOADED: "Document uploaded successfully.",
  FILE_UPLOADED: "File uploaded successfully.",
  //Password
  PASSWORD_RESETED:
    "Password reset request successfuly. Check your email for the OTP.",
  //OTP
  OTP_VERFIED: "OTP verified successfully.",
  //Reset
  PASSWORD_RESETED_SUCC: "Password reset successfully.",
  //logout
  LOG_OUT_SUCC: "Logged out successfully",
  LOG_OUT: "You have been logged out.",
  //ADD ADD
  ADDMINSTRATOR_ADDED: "Administrator added successfully.",
  SPONSOR_ADDED: "Sponsor added successfully.",
  PARTICIPANT_ADDED: "Participant added successfully.",
  PROFILE_ADDED: "Profile updated successfully.",
  PRODILE_DELETE: "Profile deleted successfully",
});

export const LOADING_MESSAGES = Object.freeze({
  PASSWORD_LOADING: "Requesting password reset...",
  OTP_LOADING: "Verifying OTP...",
  RESET_LOADING: "Requesting password reset...",
  LOGOUT_LOADING: "Logging out...",
  PROFILE_LOADING: "Updating profile...",
  DELETE_LOADING: "Deleteing profile..",
});
