import { RO_CONSTANTS } from "@/constants/RolloverConstants";
import { PortfolioPermId, AccessCode, PortfolioTypeId } from "@/types";
import { RolloverPost, downloadBlobPost } from "./requestMethods";

export const GET_ACCESS_CODE = (): Promise<string> =>
  RolloverPost(
    `${RO_CONSTANTS.API_CATEGORY.ACCESS_CODE}/${RO_CONSTANTS.API_CATEGORY_TYPES.ACCESS_CODE_API.GET_ACCESS_CODE}`,
    {
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      AccessKey: RO_CONSTANTS.ACCESS.KEY,
    }
  );

//rollover changings
export const INTERNAL_PORTFOLIO_INQ = (
  portfolioPermId: PortfolioPermId,
  AccessCode: AccessCode
) =>
  RolloverPost(RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .PORTFOLIO_INQ,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermId: Number(portfolioPermId),
    },
    HashTag: "",
  });

export const PORTFOLIO_INQ = (
  portfolioPermId: PortfolioPermId,
  AccessCode: AccessCode
) =>
  RolloverPost(RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.EXTERNAL_RQ
        .PORTFOLIO_INQ,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermId: Number(portfolioPermId),
    },
    HashTag: "",
  });

export const LIST_USERS = (
  portfolioPermId: any,
  PortfolioTypeId: PortfolioTypeId,
  AccessCode: AccessCode
) =>
  RolloverPost(RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .PORTFOLIO_INQ,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermId: Number(portfolioPermId),
      PortfolioTypeId: PortfolioTypeId,
    },
    HashTag: "",
  });

export const TRANSACTION_INQ_RQ = (
  portfolioPermId: PortfolioPermId,
  startDate: string,
  endDate: string,
  AccessCode: AccessCode
) =>
  RolloverPost(RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.EXTERNAL_RQ
        .TRANSACTION_INQ,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermID: Number(portfolioPermId),
      StartDt: startDate,
      EndDt: endDate,
    },
    HashTag: "",
  });

export const FETCH_TRANSACATION_DETAIL = (
  PortfolioPermId: any,
  TransactionPermId: number,
  AccessCode: AccessCode
) =>
  RolloverPost(RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.EXTERNAL_RQ
        .TRANSACTION_DETAIL,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermId: Number(PortfolioPermId),
      TransactionPermId: TransactionPermId,
    },
    HashTag: "",
  });

export const PAGE_PERMISSION = (
  siteId: string,
  pageId: string,
  AccessCode: AccessCode
) =>
  RolloverPost(
    `${RO_CONSTANTS.API_CATEGORY.ACCESS_CODE}/${RO_CONSTANTS.API_CATEGORY_TYPES.ACCESS_CODE_API.PAGE_PERMISSION}`,
    {
      AccessCode: AccessCode,
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      AccessKey: RO_CONSTANTS.ACCESS.KEY,
      SiteId: siteId,
      PageId: pageId,
    }
  );

// Add Plan Advisor
export const INTERNAL_PLAN_ADVISOR_ADD = (
  AccessCode: AccessCode,
  apiData: any
) =>
  RolloverPost(RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .ADD_ADVISOR_AGENT,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      ...apiData,
    },
    HashTag: "",
  });

// Add Paricipant
export const INTERNAL_PARTICIPANT_ADD_REQ = (
  AccessCode: AccessCode,
  apiData: any
) =>
  RolloverPost(RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .ADD_PARTICIPANT,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...apiData,
    },
    HashTag: "",
  });

export const GET_USER_LOGIN = (
  userName: string,
  password: string,
  identityToken: null | string
) =>
  RolloverPost(
    `${RO_CONSTANTS.API_CATEGORY.ACCESS_CODE}/${RO_CONSTANTS.API_CATEGORY_TYPES.ACCESS_CODE_API.GET_USER_LOGIN}`,
    {
      UserName: userName,
      Password: password,
      IdentityToken: identityToken,
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      AccessKey: RO_CONSTANTS.ACCESS.KEY,
    }
  );

export const PORTFOLIO_ADDRESS_ADD_RQ = (data: any, accessCode: string) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.EXTERNAL_RQ
        .ADD_ADDRESS,
    AccessCode: accessCode,
    PartnerPermId: Number(process.env.REACT_APP_ROLLOVER_PARTNER_PERM_ID),
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...data,
    },
    HashTag: "",
  });

export const PORTFOLIO_CONTACT_MOD_RQ = (data: any, accessCode: string) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.EXTERNAL_RQ
        .CONTACT_MOD,
    AccessCode: accessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...data,
    },
    HashTag: "",
  });

export const INTERNAL_PARTNER_TODO_INQ_RQ = (
  agentPermId: number,
  rowsPerPage: number,
  pageNumber: number,
  status?: number,
  accessCode?: AccessCode
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .TODO_LIST,
    AccessCode: accessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      AgentPermId: agentPermId,
      RowsOfPage: rowsPerPage,
      PageNumber: pageNumber,
      Status: status,
    },
    HashTag: "",
  });
export const INTERNAL_PARNTER_TODO_COMPLETE_RQ = (
  portfolioPermId: any,
  toDoPermId: number,
  accessCode?: AccessCode
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .COMPLETE_TODO,
    AccessCode: accessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermId: Number(portfolioPermId),
      ToDoPermId: toDoPermId,
    },
    HashTag: "",
  });

export const INTERNAL_PARTNER_TODO_ARCHIVE_RQ = (
  portfolioPermId: any,
  toDoPermId: number,
  accessCode?: AccessCode
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .ARCHIVE_TODO,
    AccessCode: accessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermId: Number(portfolioPermId),
      ToDoPermId: toDoPermId,
    },
    HashTag: "",
  });

export const INTERNAL_PARTICIPANT_UPLOAD_RQ = (
  sheetData: any,
  accessCode: string
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .UPLOAD_PARTICIPANT,
    AccessCode: accessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...sheetData,
    },
    HashTag: "",
  });

export const INTERNAL_UPLOAD_REQUIRED_DOCUMENT_RQ = (
  sheetData: any,
  accessCode: string
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .UPLOAD_DOCS,
    AccessCode: accessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...sheetData,
    },
    HashTag: "",
  });

// registration
export const INTERNAL_ADMINISTRATOR_ADD_RQ = (
  formData: any,
  accesscode: string
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .ADD_ADMINISTRATOR,
    AccessCode: accesscode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      ...formData,
    },
    HashTag: "",
  });
export const INTERNAL_ADVISOR_ADD_RQ = (formData: any, accesscode: string) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .ADD_ADVISOR,
    AccessCode: accesscode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...formData,
    },
    HashTag: "",
  });

export const INTERNAL_SPONSOR_ADD_RQ = (
  formData: any,
  accesscode: string | null
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.INTERNAL_RQ
        .ADD_SPONSOR,
    AccessCode: accesscode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...formData,
    },
    HashTag: "",
  });

export const PORTFOLIO_STATUS_MOD_RQ = (
  formData: any,
  accesscode: string | null
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}/`, {
    APIName:
      RO_CONSTANTS.API_CATEGORY_TYPES.EXTERNAL_MANAGEMENT_API.EXTERNAL_RQ
        .STATUS_MOD,
    AccessCode: accesscode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      ...formData,
    },
    HashTag: "",
  });

export const CHECK_USER_NAME = (username: string) =>
  RolloverPost(
    `${RO_CONSTANTS.API_CATEGORY.ACCESS_CODE}/${RO_CONSTANTS.API_CATEGORY_TYPES.ACCESS_CODE_API.CHECK_USER_NAME}`,
    {
      Username: username,
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      AccessKey: RO_CONSTANTS.ACCESS.KEY,
    }
  );

export const USER_REQUEST_RESET_PASSWORD = (
  username: string,
  EmailAddress: string
) =>
  RolloverPost(
    `${RO_CONSTANTS.API_CATEGORY.ACCESS_CODE}/${RO_CONSTANTS.API_CATEGORY_TYPES.ACCESS_CODE_API.USER_RESET_PW_RQ}`,
    {
      Username: username,
      EmailAddress: EmailAddress,
      AccessKey: RO_CONSTANTS.ACCESS.KEY,
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    }
  );

export const USER_RESET_PASSWORD = (
  username: string,
  newpassword: string,
  authcode: string,
  referencevalue: string
) =>
  RolloverPost(
    `${RO_CONSTANTS.API_CATEGORY.ACCESS_CODE}/${RO_CONSTANTS.API_CATEGORY_TYPES.ACCESS_CODE_API.USER_RESET_PW}`,
    {
      Username: username,
      NewPassword: newpassword,
      AuthCode: authcode,
      ReferenceValue: referencevalue,
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      AccessKey: RO_CONSTANTS.ACCESS.KEY,
    }
  );
export const VALIDATE_TFA_CODE = (
  authcode: string,
  referencevalue: string,
  saveidentity: boolean,
  useripaddress: string
) =>
  RolloverPost(
    `${RO_CONSTANTS.API_CATEGORY.ACCESS_CODE}/${RO_CONSTANTS.API_CATEGORY_TYPES.ACCESS_CODE_API.VALIDATE_TFA_CODE}`,
    {
      PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
      AccessKey: RO_CONSTANTS.ACCESS.KEY,
      AuthCode: authcode,
      ReferenceValue: referencevalue,
      SaveIdentity: saveidentity,
      UserIPAddress: useripaddress,
    }
  );
export const GET_DOC_LIST = (
  portfolioPermId: any,
  Required: boolean,
  AccessCode: AccessCode
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}`, {
    APIName: RO_CONSTANTS.API_CATEGORY_TYPES.PORTFOLIO_MGT.GET_DOCS,
    AccessCode: AccessCode,
    PartnerPermId: process.env.REACT_APP_ROLLOVER_PARTNER_PERM_ID,
    AccessKey: process.env.REACT_APP_ROLLOVER_API_ACCESS_KEY,
    Encrypted: {
      PortfolioPermId: portfolioPermId,
      Required,
    },
    HashTag: "",
  });
export const DOWNLOAD_DOCS = (
  portfolioPermId: any,
  DocumentPermId: any,
  AccessCode: AccessCode
) =>
  downloadBlobPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}`, {
    APIName: RO_CONSTANTS.API_CATEGORY_TYPES.PORTFOLIO_MGT.DOWNLOAD_DOCS,
    AccessCode: AccessCode,
    PartnerPermId: process.env.REACT_APP_ROLLOVER_PARTNER_PERM_ID,
    AccessKey: process.env.REACT_APP_ROLLOVER_API_ACCESS_KEY,
    Encrypted: {
      PortfolioPermId: portfolioPermId,
      DocumentPermId: DocumentPermId,
    },
    HashTag: "",
  });

export const GET_PORTFOLIO_DOC = (
  portfolioPermId: any,
  Required: boolean,
  AccessCode: AccessCode
) =>
  RolloverPost(`${RO_CONSTANTS.API_CATEGORY.EXTERNAL_MANAGEMENT}`, {
    APIName: RO_CONSTANTS.API_CATEGORY_TYPES.PORTFOLIO_MGT.GET_DOCS,
    AccessCode: AccessCode,
    PartnerPermId: RO_CONSTANTS.PARTNER_PERM.ID,
    AccessKey: RO_CONSTANTS.ACCESS.KEY,
    Encrypted: {
      PortfolioPermId: Number(portfolioPermId),
      Required,
    },
    HashTag: "",
  });
