import React, { useCallback, useEffect, useState } from "react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDText,
  TXDImage,
  TXDSelect,
} from "@savedaily/txd-components";
import curveSvg from "../../../assets/svg/curve.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { updateFormField } from "@/redux/slices/registrationSlice";
import { setStep } from "@/redux/slices/registrationSlice";
import { CHECK_USER_NAME } from "@/services/api/userService";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
import { ERROR_MESSAGES } from "@/layouts/skeletons/toastUtils";
const TPAStartRollover: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRetypePassword, setShowPasswordRetypePassword] =
    useState<boolean>(false);

  const {
    firstName,
    middleName,
    lastName,
    email,
    userName,
    password,
    accountType,
    retypePassword,
  } = useSelector((state: RootState) => state.registrationForm);
  const [passwordError, setPasswordError] = useState("");
  const [selectedPath, setSelectedPath] = useState("");
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [retypePasswordTouched, setRetypePasswordTouched] = useState(false);

  const handlePasswordChange = (field: string) => {
    const passwordInput = document.getElementById(
      "password"
    ) as HTMLInputElement;
    const retypePasswordInput = document.getElementById(
      "retypePassword"
    ) as HTMLInputElement;

    // Update touched states based on which field is being edited
    if (field === "password") {
      setPasswordTouched(true);
    } else if (field === "retypePassword") {
      setRetypePasswordTouched(true);
    }

    // Check if both fields have been touched before setting the error message
    if (passwordTouched && retypePasswordTouched) {
      const password = passwordInput.value;
      const retypePassword = retypePasswordInput.value;

      if (password !== retypePassword) {
        setPasswordError("Passwords do not match.");
      } else {
        setPasswordError("");
      }
    }
  };

  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
  });
  const [usernameError, setUsernameError] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // const accountType = e.target[0].value;
    const firstName = e.target[1].value;
    const middleName = e.target[2].value;
    const lastName = e.target[3].value;
    const email = e.target[4].value;
    const userName = e.target[5].value;
    const password = e.target[6].value;
    const retypePassword = e.target[8].value;

    // console.log({
    //   accountType,
    //   firstName,
    //   middleName,
    //   lastName,
    //   email,
    //   userName,
    //   password,
    //   retypePassword,
    // });

    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError || passwordError) {
      toast.error(ERROR_MESSAGES.ERROR_SUBMIT);
      return;
    } else {
      dispatch(updateFormField({ field: "firstName", value: firstName }));
      dispatch(updateFormField({ field: "lastName", value: lastName }));
      dispatch(updateFormField({ field: "middleName", value: middleName }));
      dispatch(updateFormField({ field: "email", value: email }));
      dispatch(updateFormField({ field: "userName", value: userName }));
      dispatch(updateFormField({ field: "password", value: password }));
      dispatch(
        updateFormField({ field: "retypePassword", value: retypePassword })
      );
      dispatch(setStep(2));
      navigate(selectedPath);
    }
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };

  const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const debouncedCheckUsername = useCallback(
    debounce(async (userName: string) => {
      try {
        const response = await CHECK_USER_NAME(userName);
        if (response) {
          setUsernameError(ERROR_MESSAGES.USERNAME_TAKEN);
        } else {
          setUsernameError("");
        }
      } catch (error) {
        console.error(ERROR_MESSAGES.USERNAME_TAKEN_FAILED, error);
      }
    }, 500),
    []
  );

  const handleUsernameChange = (e: { target: { value: any } }) => {
    const { value } = e.target;
    dispatch(updateFormField({ field: "userName", value }));
    debouncedCheckUsername(value);
  };
  const handleSelectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newPath = event.target.value;
    dispatch(updateFormField({ field: "accountType", value: newPath }));
    setSelectedPath(newPath);
  };
  useEffect(() => {
    setSelectedPath(accountType);
  }, [accountType]);
  // const handleInputChange = (field: keyof IRegistrationState, value: any) => {
  //   dispatch(updateFormField({ field, value }));
  // };
  return (
    <TXDBox className="flex flex-col justify-center items-center relative px-4">
      <TXDText
        as="h1"
        size="2xl"
        weight="medium"
        className="text-[#333333] mt-16 mb-8"
      >
        Welcome Please Begin by Registering Below
      </TXDText>
      <TXDFlex className="flex-col w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-[428px] gap-4 z-10">
        <form onSubmit={handleSubmit}>
          <TXDBox>
            <TXDSelect
              id="accountType"
              defaultValue={accountType}
              onChange={handleSelectionChange}
              isObjectArray
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent border border-gray-200"
              labelText="Select Account Type"
              optionsArray={[
                {
                  disabled: true,
                  label: "-- Select Account Type --",
                  value: "",
                },
                {
                  label: "Third Party Administrator",
                  value: "/tpa-create-account",
                },
                { label: "Sponsor", value: "/ps-create-account" },
                { label: "Advisor", value: "/pa-create-account" },
              ]}
              sizing="xs"
              required
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="firstName"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="First Name"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              minLength={1}
              maxLength={30}
              regex={REGEX_CONSTANTS.FIRST_NAME_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input2", error ? true : false)
              }
              initialValue={firstName}
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="middleName"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="Middle Name (Optional)"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              // minLength={0}
              maxLength={30}
              regex={REGEX_CONSTANTS.MIDDLE_NAME_REGEX}
              errorExist={(error: any) =>
                updateErrorState("input3", error ? true : false)
              }
              initialValue={middleName}
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="lastName"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="Last Name"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              minLength={1}
              maxLength={30}
              regex={REGEX_CONSTANTS.LAST_NAME_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input4", error ? true : false)
              }
              initialValue={lastName}
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="email"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="Email Address"
              radius="md"
              sizing="xs"
              type="email"
              variant="regular"
              regex={REGEX_CONSTANTS.EMAIL_REGEX}
              className="focus:ring-0 focus:ring-transparent"
              required
              errorExist={(error: any) =>
                updateErrorState("input5", error ? true : false)
              }
              initialValue={email}
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="userName"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="Username"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              minLength={2}
              maxLength={30}
              regex={REGEX_CONSTANTS.USERNAME_REGEX}
              required
              className="focus:ring-0 focus:ring-transparent"
              initialValue={userName}
              errorExist={(error: any) =>
                updateErrorState("input6", error ? true : false)
              }
              onBlur={handleUsernameChange}
            />
            {usernameError && (
              <TXDText className="text-red-500 text-xs mt-1">
                {usernameError}
              </TXDText>
            )}
          </TXDBox>
          <TXDBox className="relative">
            <TXDInput
              id="password"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="Password"
              radius="md"
              sizing="xs"
              type={showPassword ? "text" : "password"}
              variant="regular"
              className="focus:ring-0 focus:ring-transparent w-full"
              regex={REGEX_CONSTANTS.PASSWORD_REGEX}
              minLength={8}
              maxLength={30}
              required
              onInput={() => handlePasswordChange("password")}
              errorExist={(error: any) =>
                updateErrorState("input7", error ? true : false)
              }
              initialValue={password}
            />
            <button
              type="button"
              className="absolute top-[2.1rem] right-0 flex items-center pr-3"
              onClick={() => setShowPassword((prev) => !prev)}
              data-testid="toggle-password-visibility"
            >
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash}
                className="text-[#A2A2A2]"
              />
            </button>
          </TXDBox>
          <TXDText as="p" size="xs" weight="normal" className="text-gray-400">
            (Minimum 8 characters, one upper and lower case, one numeric and one
            special character)
          </TXDText>
          <TXDBox className="relative">
            <TXDInput
              id="retypePassword"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="Retype Password"
              radius="md"
              sizing="xs"
              type={showRetypePassword ? "" : "password"}
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              required
              onInput={() => handlePasswordChange("retypePassword")}
              errorExist={(error: any) =>
                updateErrorState("input8", error ? true : false)
              }
              initialValue={retypePassword}
            />
            <button
              type="button"
              className="absolute top-[2.1rem] right-0 flex items-center pr-3"
              onClick={() => setShowPasswordRetypePassword((prev) => !prev)}
              data-testid="toggle-password-visibility"
            >
              <FontAwesomeIcon
                icon={showRetypePassword ? faEye : faEyeSlash}
                className="text-[#A2A2A2]"
              />
            </button>
          </TXDBox>
          {passwordError && (
            <TXDText className="my-1 text-red-500 text-sm font-normal">
              {passwordError}
            </TXDText>
          )}
          <TXDFlex justifyContent="center" className="gap-[28px] mt-6">
            <Link to={"/sign-in"}>
              <TXDButton
                radius="md"
                size="sm"
                variant="primary"
                className="bg-[#757575] uppercase  py-2 text-xs md:text-xs lg:text-sm shadow-sm  ring-secondary-400 hover:bg-white hover:text-[#757575] hover:ring-1  w-[100px]"
              >
                CANCEL
              </TXDButton>
            </Link>
            <TXDButton
              radius="md"
              size="sm"
              variant="primary"
              className="bg-[#52A546] py-2 text-white text-xs md:text-xs lg:text-sm shadow-sm hover:bg-white hover:text-[#52A546] hover:ring-1 hover:ring-[#52A546] hover:shadow-lg w-[100px]"
              type="submit"
            >
              NEXT
            </TXDButton>
          </TXDFlex>
        </form>
      </TXDFlex>
      <TXDImage
        src={curveSvg}
        className="absolute top-[410px] w-full h-[1024px] opacity-100 z-0"
      />
    </TXDBox>
  );
};

export default TPAStartRollover;
