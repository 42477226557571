import React, { useState } from "react";
import {
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDText,
  TXDTextarea,
  TXDImage,
  TXDCheckbox,
} from "@savedaily/txd-components";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import curveSvg from "../../../assets/svg/curve.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "@/layouts/skeletons/toastUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";

const PSConfirmation: React.FC = () => {
  const defaultText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`;
  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.ERROR_SUBMIT);
      return;
    }
    const username = e.target[0].value;
    const title = e.target[1].value;
    const date = e.target[2].value;

    const formData = {
      username,
      title,
      date,
    };

    console.table(formData);
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <TXDFlex
          justifyContent="center"
          alignItems="center"
          className="  relative  px-4"
          variant="column"
        >
          <TXDText
            as="h1"
            size="2xl"
            weight="medium"
            className="text-[#333333] mt-16 mb-8"
          >
            Confirm my disclosures
          </TXDText>
          <TXDFlex className="flex-col w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/3  max-w-[516px]   relative z-10">
            <TXDText className=" text-[20px] " weight="normal">
              <span className="text-[#00AFD8]">Welcome</span> You will need to
              review the following disclosures and acknowledge and/or agree to
              them
            </TXDText>

            <TXDText className="mt-11 text-[17px] " weight="normal">
              Acknowledgement and Agreements:
            </TXDText>
            <TXDFlex className=" mb-9">
              <TXDCheckbox
                checkboxArray={[
                  {
                    id: "Acknowledge",

                    value: "Acknowledge",
                  },
                ]}
                inline
                isObjectArray
                labelClass=""
                className="h-4 w-4 rounded border-gray-300 text-[#00AFD8] focus:ring-0 outline-0 cursor-pointer"
              />
              <TXDText
                as="label"
                htmlFor="Acknowledge"
                className="leading-4 text-[12px] font-normal cursor-pointer"
              >
                I acknowledge that I have read and reviewed the{" "}
                <span className="text-[#00AFD8]">Privacy statement</span> and
                the <span className="text-[#00AFD8]">Terms and Conditions</span>{" "}
                for use of this website.
              </TXDText>
            </TXDFlex>

            <TXDText className="text-[#00AFD8] text-[20px]">
              Automatic IRA Rollover Agreement
            </TXDText>
            <TXDTextarea
              placeholder="Write message here"
              radius="md"
              rows={4}
              className="w-full overflow-y-auto focus:ring-0 focus:ring-transparent  participant-table"
              defaultValue={defaultText}
            />
            <TXDFlex className="gap-2 mt-10">
              <FontAwesomeIcon
                icon={faPrint}
                className="h-4 w-4  text-gray-400 mt-1"
              />
              <TXDCheckbox
                checkboxArray={[
                  {
                    id: "Acknowledge",

                    value: "Acknowledge",
                  },
                ]}
                inline
                isObjectArray
                labelClass=""
                className="h-4 w-4 rounded border-gray-300 text-[#00AFD8] focus:ring-0 outline-0 cursor-pointer"
              />
              <TXDText
                className="leading-4 text-[12px]    ml-[-7px] "
                weight="normal"
              >
                I have read and agree to this agreement and under of perjury I
                am the person indicated below and have the authority to sign
                this agreement on behalf of the plan specified in this
                agreement.
              </TXDText>
            </TXDFlex>

            <TXDInput
              id="username"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs "
              labelText="NAME:"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent uppercase "
              regex={REGEX_CONSTANTS.USERNAME_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input1", error ? true : false)
              }
            />

            <TXDInput
              id="title"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs "
              labelText="TITLE:"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent uppercase "
              regex={REGEX_CONSTANTS.TITLE_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input2", error ? true : false)
              }
            />
            <TXDInput
              id="date"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs "
              labelText="DATE:"
              radius="md"
              sizing="xs"
              type="date"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent uppercase "
              required
              errorExist={(error: any) =>
                updateErrorState("input3", error ? true : false)
              }
            />
            <TXDFlex justifyContent="center" className="gap-[28px] mt-6">
              <TXDButton
                radius="md"
                size="sm"
                variant="primary"
                className="bg-[#757575] uppercase  py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-white hover:text-[#757575] hover:ring-secondary-400   w-[100px]"
              >
                CANCEL
              </TXDButton>
              <TXDButton
                radius="md"
                size="sm"
                variant="primary"
                className="bg-[#52A546] py-2  text-white text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 
              hover:bg-white hover:text-[#52A546] hover:ring-[#52A546] hover:shadow-lg   w-[100px]"
              >
                NEXT
              </TXDButton>
            </TXDFlex>
          </TXDFlex>

          <TXDImage
            src={curveSvg}
            className="absolute top-[410px] w-full h-[1024px] opacity-100 z-0"
          ></TXDImage>
        </TXDFlex>
      </form>
    </>
  );
};

export default PSConfirmation;
