import React from "react";
import { TXDBox, TXDFlex, TXDImage, TXDText } from "@savedaily/txd-components";
import rollOverLogo from "../assets/images/logo-rollover.png";
import { INavRightSection } from "@/interfaces";

const NavRightSection: React.FC<INavRightSection> = ({ data }) => {
  return (
    <>
      <TXDBox className="overflow-hidden hidden bg-[#ffffff] md:grid lg:grid xl:grid col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-7  md:divide-y-0">
        <TXDFlex className="px-8 py-4 justify-end">
          <TXDImage
            src={rollOverLogo}
            alt="save-daily-logo"
            className="w-100 h-10"
          />
        </TXDFlex>
      </TXDBox>
    </>
  );
};

export default NavRightSection;
